import React, { useEffect, useState, useRef } from 'react';
// Animation
import { useInViewStart } from '~/hooks/useInViewStart';
import { useAutoProgressTimeout } from '~/hooks/useAutoProgressTimeout';
// Content
import Image from "~/components/elements/Image";
// Link
import { Link } from 'gatsby';
import { ButtonArrow } from '../../elements/buttons/Button';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';


export const TwoColumnGridSwiper = ({ 
  gridItems, 
  image, 
  backgroundImage,
  galleryBackground, 
  borderStyle
}) => {
  const gridClasses = ['grid grid-cols-1 md:grid-cols-2 md:gap-x-8 justify-center items-center'];
  const imageClasses = ['w-full md:h-full flex md:items-center justify-end  rounded-2xl mb-8 md:mb-0 md:mr-10 mx-auto order-first md:order-last overflow-hidden safari-overflow background-controls '];
  
  if(galleryBackground === 'fadedGreen'){
    imageClasses.push(' bg-fadedGreen')
  } else if (galleryBackground === 'fadedMaroon') {
    imageClasses.push(' bg-fadedMaroon')
  }

  return (
    <InViewAnimator className="container center-swiper"
      inner={(controls) => (
        <div className={gridClasses.join(' ')}>
          <div className="flex flex-col flex-1 w-full md:h-full md:items-stretch md:justify-stretch space-y-4">
              {gridItems.map((item, i) => {
                return (
                  <TextItem
                    key={i}
                    item={item}
                    borderStyle={borderStyle}
                    controls={controls}
                    staggerDelay={i}
                  />
                );
              })}
          </div>
          <div className={imageClasses.join(' ')} 
            style={ backgroundImage ? 
              { backgroundImage: `url("${backgroundImage.localFile.publicURL}")`} : {}}>
            <ImageDiv image={image} controls={controls} />
          </div>
        </div>
       )}
    />
  );
};

export const ImageDiv = ({ 
  image,
  controls
}) => {

  return (
    <div className="relative overflow-hidden rounded-lg py-6 pl-6 md:py-[40px]">
      <InViewAnimatorBasicItem className='w-auto h-auto'
        controls={controls}
        delay={.8}
        duration={1} 
        prop='x'
        start={30}>
        <Image image={image} className='max-w-[480px] md:max-w-[690px] max-h-[550px] w-full h-full ml-auto z-10' />
      </InViewAnimatorBasicItem>
    </div>
  );
};

export const Background = ({
  image
}) => {
  return(
    <>
      style={{ 
        backgroundImage: `url("${image.localFile.publicURL}")` 
    }}
  </>
  )
}

export const TextItem = ({ item, borderStyle, controls, staggerDelay }) => {

  const borderClasses = [];
  
  if(borderStyle === 'dark'){
    borderClasses.push('border-dark-30');
  } else {
    borderClasses.push('border-white-30');
  }
  if(item?.link){
  return (
    <InViewAnimatorBasicItem className={`md:h-full relative rounded-2xl border ${borderClasses} py-8 px-6 pr-8 md:pl-[40px] lg:pl-[52px] lg:px-8 lg:pr-[42px] lg:py-[42px]`}
      controls={controls}
      delay={staggerDelay/3}
      duration={1}>
        <Link
          to={item?.link?.url || '#'}
          className="flex flex-col group hover:opacity-60 transition-all duration-200">
      <h4 className="relative flex items-center justify-between t-24 duration-500">
        <span>
          {item?.heading}
        </span>
      </h4>
        <div>
          <p className="relative pt-4 t-16 max-w-[370px]">{item?.content}</p>
          {item?.link &&
            <span
              className={`mt-5 t-16 font-medium flex items-center space-x-[9px] whitespace-nowrap`}
            >
              <span>{item?.link?.title}</span>
              <span className="inline-block group-hover:translate-x-4 transition-all duration-200">
                <ButtonArrow arrowFill={true} />
              </span>
            </span>
          }
        </div>
        </Link>
    </InViewAnimatorBasicItem>
  );
  } else {
    return (
      <InViewAnimatorBasicItem className={`md:h-full relative rounded-2xl border ${borderClasses} py-8 px-6 pr-8 md:pl-[40px] lg:pl-[52px] lg:px-8 lg:pr-[42px] lg:py-[42px]`}
        controls={controls}
        delay={staggerDelay/3}
        duration={1}>
        <h4 className="relative flex items-center justify-between t-24 duration-500">
          <span>
            {item?.heading}
          </span>
        </h4>
          <div>
            <p className="relative pt-4 t-16 max-w-[370px]">{item?.content}</p>
          </div>
      </InViewAnimatorBasicItem>
    );
  }
};
