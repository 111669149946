import React from "react";
import Image from "../../elements/Image";
import { Link } from "gatsby";
import { ButtonArrow } from "../../elements/buttons/Button";
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const TwoColumnStickyCard = ({
  stickyCard,
  cards,
  cardsContent,
  cardBackground,
  reverse,
}) => {

  const reverseContent = [];
  if(reverse){
    reverseContent.push(' md:order-first');
  }

  const cardClasses = ['rounded-[2rem] border border-borderGrey '];
  if(cardBackground === 'darkGreen'){
    cardClasses.push(' bg-darkGreen text-white');
  } else if(cardBackground === 'maroon'){
    cardClasses.push(' bg-maroon text-white');
  } else if(cardBackground === 'darkTaupe'){
    cardClasses.push(' bg-darkTaupe');
  } else if(cardBackground === 'darkBlue'){
    cardClasses.push(' bg-darkBlue text-white');
  } else if(cardBackground === 'blue'){
    cardClasses.push(' bg-blue');
  } else if(cardBackground === 'midGreen'){
    cardClasses.push(' bg-midGreen');
  } else if(cardBackground === 'white'){
    cardClasses.push(' bg-white');
  }

  return(
    <InViewAnimator className="section-lrg grid grid-cols-1 md:grid-cols-2 gap-y-8 md:gap-y-0 md:gap-x-[24px]"
      inner={(controls) => (
        <>
          <div className="relative block">
            <StickyCard card={stickyCard} cardClasses={cardClasses} controls={controls} reverse={reverse} />
          </div>
          <div className={`${reverseContent} space-y-[20px] ${cardsContent === 'link' ? 'md:space-y-[30px]' : ''}`}>
            {cardsContent === 'link' ?
              <>
                {cards.map((card, i) => (
                  <LinkCard key={i} card={card} cardClasses={cardClasses} staggerDelay={i} controls={controls} />
                ))} 
              </>
              :
                <>
                {cards.map((card, i) => (
                  <IconCard key={i} card={card} cardClasses={cardClasses} staggerDelay={i} controls={controls} />
                ))}
              </>
            } 
          </div>
        </>
      )}
    />
  );
};

export const StickyCard = ({
  card,
  cardClasses,
  controls,
  reverse
}) => {

  const startProp = [];

  if(reverse){
    startProp.push('30');
  } else {
    startProp.push('-30');
  }

  return(
    <InViewAnimatorBasicItem className={`md:sticky md:top-24 ${cardClasses} text-center px-8 md:pb-16 lg:px-[88px] pt-8 md:pt-16 lg:pt-[92px] pb-12 md:pb-20 lg:pb-24`}
      controls={controls}
      delay={.1}
      prop={'x'}
      start={`${startProp}`}
      duration={1}>
      <div className="w-[125px] h-[125px] mx-auto mb-6 md:mb-8">
        <Image image={card.icon} className={`w-full h-full object-contain object-center`} />
      </div>
      <h2 className="text-h2 max-w-[390px] mx-auto mb-4">{card.heading}</h2>
      <p className="max-w-[372px] mx-auto" dangerouslySetInnerHTML={{ __html: card.content }} />
    </InViewAnimatorBasicItem>
  );
;}

export const LinkCard = ({
  card,
  cardClasses,
  staggerDelay,
  controls
}) => {

  return(
    <InViewAnimatorBasicItem className={`${cardClasses} text-center md:text-left px-8 md:pb-16 lg:px-[85px] pt-10 md:pt-16 lg:pt-[82px] pb-8 md:pb-12 lg:pb-16`}
      controls={controls}
      delay={staggerDelay/4}
      duration={1}>
      <div className="w-[78px] h-[78px] mb-2 mx-auto md:ml-0 mb-4 md:mb-[20px]">
        <Image image={card.icon} className={`w-full h-full object-contain object-center`} />
      </div>
      <h4 className="t-35 mb-4">{card.heading}</h4>
      {card.content &&
        <p className="t-18 max-w-[395px] mx-auto md:ml-0 mb-4" dangerouslySetInnerHTML={{ __html: card.content }} />
      }
      {card.link &&
        <Link
          to={card.link?.url || '#'}
          className={`flex items-center space-x-[9px] whitespace-nowrap justify-center md:justify-start`}
         >
          <span>{card.link?.title}</span>
          <span className="inline-block w-[24px] h-[24px]">
            <ButtonArrow arrowFill={true} />
          </span>
        </Link>
        }
    </InViewAnimatorBasicItem>
  );
};

export const IconCard = ({
  card,
  cardClasses,
  staggerDelay,
  controls
}) => {

  return(
    <InViewAnimatorBasicItem className={`${cardClasses} flex flex-col md:flex-row items-center space-y-6 md:space-y-0 md:space-x-[30px] px-6 md:pl-8 md:pr-12 lg:pr-[116px] py-6 md:py-8 lg:py-42`}
      controls={controls}
      delay={staggerDelay/4}
      duration={1}>
      <div className="w-[80px] h-[80px]">
        <Image image={card.icon} className={`w-full h-full object-contain object-center`} />
      </div>
      <h4 className="text-h4 text-center md:text-left">{card.heading}</h4>
    </InViewAnimatorBasicItem>
  );
};