import React from "react";
// Link
import { Link } from 'gatsby';
import { ButtonArrowUnset } from "../../elements/Icon";
// Image
import Image from "~/components/elements/Image";
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const LinkCards = ({ 
  linkCards,
}) => {

  const pathname = typeof window !== 'undefined' && window.location.pathname;

  return(
    <InViewAnimator className="link-cards-container container grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-y-5 md:gap-x-5"
      inner={(controls) => (
        <>
          {linkCards.map((card, i) => (
            <LinkCard key={i} card={card} controls={controls} staggerDelay={i} pathname={pathname} />
          ))}
        </>
      )}
    />
  );
};

export const LinkCard = ({
card,
controls,
staggerDelay,
pathname
}) => {

  return(
    <InViewAnimatorBasicItem className="h-full link-cards-card rounded-2xl relative bg-white pt-8 pb-6 pl-8 pr-6 md:pt-[40px] md:pb-[30px] md:pl-[50px] md:pr-[30px] md:min-h-[342px] lg:min-h-[305px] lg:h-[27vw] xl:h-full"
      controls={controls}
      delay={(staggerDelay)/3}
      duration={1} >
        <Link
          to={card?.link?.url || '#'}
          className={`flex flex-col flex-auto group transition-all duration-200 h-full overflow-hidden ${card?.link?.url === pathname && 'opacity-40 pointer-events-none'}`}
        >
          <div className={`w-24 h-20 md:w-[135px] md:h-[120px] mx-auto md:mr-5 mb-8 md:mb-20 group-hover:md:opacity-0 group-hover:md:mt-[-120px] group-hover:lg:mt-[-150px] transition-helper`}>
              <Image image={card.image} className={`w-full h-full object-contain object-center`}/> 
          </div>
          <div className="max-w-[88%] flex flex-col justify-between transition-all duration-200">
            <span className="span-h-help text-h4 w-auto md:w-none mb-2">{card?.link?.title}</span>
            <p className="max-w-[400px] md:opacity-0 md:max-h-[0] group-hover:opacity-100 transition-all duration-200">{card?.description}</p>
            <span className="absolute w-[27px] h-[27px] right-[30px] bottom-[30px]">
              <span className="w-[27px] h-[27px]">
                <ButtonArrowUnset arrowFill={true} />
              </span>
            </span>
          </div>
        </Link>
    </InViewAnimatorBasicItem>
  );
};