import React from 'react';
import { TextContent } from '../TextContent';
import { MediaCollection } from '../media/MediaCollection';

export const TwoColumnMediaCollection = ({ twoColContent, mediaItem, reverse }) => {
  const contentClasses = [];
  const mediaClasses = [];
  const mediaPositionClasses = [];

  if (reverse) {
    contentClasses.push('order-first md:order-last');
    mediaClasses.push('order-last md:order-first');
  } else {
    contentClasses.push('order-first lg:col-start-2');
    mediaClasses.push('order-last');
  }

  return (
    <div className="container grid grid-cols-12 items-center gap-y-8 md:gap-y-0 md:gap-x-8">
      <div className={`col-span-12 md:col-span-6 lg:col-span-5 ${contentClasses}`}>
        <TextContent innerOnly {...twoColContent} />
      </div>
      <div className={`col-span-12 md:col-span-6 ${mediaClasses}`}>
        <div className="sm:w-3/4 mx-auto md:w-full">
          <div className={`${mediaPositionClasses}`}>
            <MediaCollection collection={mediaItem} />
          </div>
        </div>
      </div>
    </div>
  );
};
