import React, { useEffect, useState, useRef } from 'react';
// Animation
import { AnimatePresence, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useInViewStart } from '~/hooks/useInViewStart';
import { useAutoProgressTimeout } from '~/hooks/useAutoProgressTimeout';
// Content
import { Swiper, SwiperSlide } from 'swiper/react';
import { TextContent } from '~/components/flexible/TextContent';
import 'swiper/css';
import { EffectFade } from 'swiper';
import 'swiper/css/effect-fade';
import Image from '~/components/elements/Image';
// Link
import { Link } from 'gatsby';
import { ButtonArrow } from '../../elements/buttons/Button';
import { HeightFixer } from '~/components/elements/Animator';

export const Accordion = ({ accordion, galleryBackground }) => {
  const [ref, started] = useInViewStart();
  const frameLength = 20;
  const [active, set_active] = useAutoProgressTimeout(started, frameLength, accordion.length - 1);
  const gridClasses = ['grid grid-cols-1 md:grid-cols-2 md:gap-x-8 justify-center'];
  const imageClasses = [
    'w-full max-w-[380px] md:max-w-[620px] mb-8 md:mb-0 md:mr-10 mx-auto order-first md:order-last',
  ];
  const imageBg = ['rounded-2xl mx-auto md:max-w-none'];

  if (galleryBackground === 'fadedGreen') {
    imageBg.push(' bg-fadedGreen');
  } else if (galleryBackground === 'fadedMaroon') {
    imageBg.push(' bg-fadedMaroon');
  }

  return (
    <div className="container center-swiper">
      <div ref={ref} className={gridClasses.join(' ')}>
        <div className="flex flex-col flex-1 w-full">
          <HeightFixer extraHeight={140} extraHeightMobile={40}>
            {accordion.map((item, i) => {
              return (
                <CarouselItem
                  key={i}
                  onClick={() => {
                    set_active(i);
                  }}
                  isActive={i === active}
                  item={item}
                  frameLength={frameLength}
                />
              );
            })}
          </HeightFixer>
        </div>
        <div className={imageClasses.join(' ')}>
          <div className={`${imageBg}`}>
            <CarouselSwiper items={accordion} active={active} set_active={set_active} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const CarouselSwiper = ({ items, active, set_active }) => {
  const [swiper, set_swiper] = useState({});

  useEffect(() => {
    if (swiper.on) {
      swiper.on('slideChange', function () {
        set_active(swiper.activeIndex);
      });
    }
  }, [swiper]);

  useEffect(() => {
    if (swiper.slideTo && swiper.activeIndex !== active) {
      swiper.slideTo(active);
    }
  }, [swiper, active]);

  return (
    <div className="relative overflow-hidden rounded-lg swiper-right-fade">
      <Swiper
        onInit={(ev) => {
          set_swiper(ev);
        }}
        modules={[EffectFade]}
        effect="fade"
        fadeEffect={{ crossFade: items[0]?.image?.type === 'lottie' ? false : true }}
        spaceBetween={0}
        slidesPerView={1}
      >
        {items.map((item, i) => (
          <SwiperSlide key={i}>
            <Image image={item.media} className="max-w-[100%] w-full h-auto ml-auto" active={active} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export const CarouselItem = ({ item, isActive, onClick, frameLength }) => {
  return (
    <div
      className={`relative bg-taupe text-darkGreen cursor-pointer mb-4 rounded-2xl py-8 px-6 md:pl-[40px] pr-8 xl:pl-[54px] xl:pr-[42px] xl:py-[42px] ${
        isActive && 'p-6'
      }`}
      onClick={onClick}
    >
      {isActive && (
        <div className="ml-12 md:ml-14 h-[1px] absolute bottom-0 left-0 right-0">
          <AnimatePresence>
            {isActive && (
              <motion.div
                initial={{ width: 0, opacity: 1 }}
                animate={{ width: '100%', opacity: 1 }}
                exit={{ opacity: 0, duration: 0.5 }}
                transition={{
                  width: {
                    duration: frameLength,
                  },
                  opacity: {
                    duration: 1,
                  },
                }}
                className={`h-full top-[100%] left-6`}
              ></motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
      <h4 className="relative flex items-center justify-between space-x-4 duration-500 t-24">
        <span>{item?.heading}</span>
        <ItemIcon status={isActive} />
      </h4>
      <AnimatePresence>
        {isActive && (
          <motion.div
            key={`${item}`}
            transition={{ type: 'tween' }}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1, transition: { duration: 0.5, opacity: { delay: 0.2 } } }}
            exit={{ height: 0, opacity: 0, transition: { delay: 0 } }}
          >
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, opacity: { delay: 0.2 } }}
              className="relative pt-4 t-16 max-w-[370px]"
            >
              {item?.content}
            </motion.p>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, opacity: { delay: 0.2 } }}
              exit={{ opacity: 0, transition: { delay: 0 } }}
            >
              <Link
                to={item?.link?.url || '#'}
                className={`mt-5 t-16 font-medium flex items-center space-x-[9px] whitespace-nowrap group hover:opacity-60 transition-all duration-200`}
              >
                <span>{item?.link?.title}</span>
                <span className="inline-block transition-all duration-200 group-hover:translate-x-4">
                  <ButtonArrow arrowFill={true} />
                </span>
              </Link>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export const ItemIcon = ({ status }) => {
  return (
    <div className="relative w-[12px] h-[12px]">
      <span
        className={`absolute h-full w-[1.6px] bg-darkGreen transition-all duration-200 ${status ? 'rotate-90' : ''}`}
      ></span>
      <span className="absolute h-full w-[1.6px] bg-darkGreen rotate-90"></span>
    </div>
  );
};
