import React from 'react';

import InnerHTML from 'dangerously-set-html-content';

function HtmlEmbed(props) {
  const { html } = props;

  return (
    <div className="container !max-w-[1240px]">
      <div className="rounded-4xl overflow-hidden">
        <InnerHTML html={html} />
      </div>
    </div>
  );
}

export default HtmlEmbed;
