import React from "react";
import Image from "~/components/elements/Image";
import { CustomerCard } from "~/components/flexible/marqueeCards/CustomerCard";
import { ActivityCard } from "~/components/flexible/marqueeCards/ActivityCard";
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { useWindowSize } from "../../../hooks/useWindowSize";

export const MediaCollection = ({
  collection,
}) =>{

  const items = collection.postTypeMediaCollections.items;
  const columns = [];
  if(items.length > 1){
      columns.push('flex justify-between')
    }

  return(
    <div className={`container ${columns} !max-w-[1100px]`}>
      {items.map((item, i) => (
        <InnerItem item={item} key={i} delayProp={i}/>
      ))}
    </div>
  );
};

export const InnerItem = ({ 
  item,
  delayProp
}) =>{

  const itemClasses = [];

  if(item.maxWidth){
    itemClasses.push('w-full mx-auto');
  }

  return(
    <div className={`relative mediaCollectionScale ${itemClasses}`} {...maxWidthProps(item)}>
      <InViewAnimator className='mx-auto max-w-[960px]'
        inner={(controls) => (
          <> 
            {item.type === 'image' &&
              <InViewAnimatorBasicItem
              controls={controls}
              delay={delayProp/4}
              duration={1}
              prop={`${item.settings.animationProp}`}
              start={item.settings.animationValue}>
                  <Image image={item.image} className={`w-full h-full object-contain object-center`} />
              </InViewAnimatorBasicItem>
            }
            {item.addChildItems && 
              <>
                {item.childItems.map((childItem, i) => (
                  <ChildItem childItem={childItem} delayProp={i} key={i} controls={controls}/>
                ))}
              </>
            }
          </>
        )}
      />
  </div>
  );
};

export const ChildItem = ({ 
  childItem,
  controls,
  delayProp,
}) =>{  
  const positionClasses = [];

  positionClasses.push(`position-${childItem.settings.position}`)


  return(
    <div className={`absolute cards ${positionClasses}`}>
      <InViewAnimatorBasicItem 
        controls={controls}
        delay={(delayProp + 1)/2}
        duration={1.4}
        prop={`${childItem.settings.animationProp}`}
        start={childItem.settings.animationValue}>
        {childItem.type === 'image' &&
          <Image image={childItem.image} className={`w-full h-full object-contain object-center`} />
        }
        {childItem.type === 'card' &&
        <>
          {childItem.card.cardType === 'customer' && <CustomerCard card={childItem.card.customerCard} delayProp={6} />}
          {childItem.card.cardType === 'activity' && <ActivityCard card={childItem.card.activityCard} delayProp={6} />}
        </>
        }
      </InViewAnimatorBasicItem>
    </div>
  );
};

export const maxWidthProps = (item) => {
  if (!item.maxWidth) return {};

  const scaleWidth =[];

  if(item.settings.scaleWidth){
    scaleWidth.push(`${item.settings.scaleWidth}`);
  } else{
    scaleWidth.push('100');
  }

  const { width } = useWindowSize();
  return {
    style: {
      maxWidth: width > 768 ? `${item.maxWidth}px` : null,
      width: `${scaleWidth}%`,
    },
  };
};
