import React from "react";
import Image from "~/components/elements/Image";
import { Check } from '../../elements/Icon';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const ActivityCard = ({
  card,
  delayProp
 }) => {
   
  return(
    <InViewAnimator className="h-[168px] mt-4 flex items-center activity-card"
      inner={(controls) => (
        <div className="relative shadow-lg rounded-lg bg-white text-darkGreen flex items-center p-[1.8rem] mr-[35px] min-w-[350px]">
          <InViewAnimatorBasicItem className="w-[70px] h-[70px] overflow-hidden mr-5"
              controls={controls}
              delay={delayProp/4}
              duration={.5}
              prop='scale'
              start={0}
              end={1}>
            <Image image={card.logo} className={`w-full h-full object-contain object-center`} />
          </InViewAnimatorBasicItem>
          <div className="space-y-1">
            <div className="flex items-center space-x-2">
              <h4 className="p-large font-body font-medium !text-base !tracking-wide">{card.heading}</h4>
              {card.checked &&
              <InViewAnimatorBasicItem
                controls={controls}
                delay={(delayProp + 4)/4}
                duration={.5}
                prop='scale'
                start={0}
                end={1}>
                <Check/>
              </InViewAnimatorBasicItem>
              }
            </div>
            <InViewAnimatorBasicItem
              controls={controls}
              delay={(delayProp + 3)/4}
              duration={.5}
              prop='x'
              start={-20}>
              <p className={`${card.checked ? 'text-midGreen' : 'text-darkgreen'} text-sm`}>{card.status}</p>
            </InViewAnimatorBasicItem>
            <p className="opacity-60 text-sm">{card.message}</p>
          </div>
        </div>
      )}
    />
  );
};