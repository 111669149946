import React from "react";
import { TextContent } from '../TextContent';
import { SimpleMedia } from '../media/SimpleMedia';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const ContainedMasthead = ({
  mastheadContent,
  mediaItem,
  reverse,
  mediaPosition,
}) => {

  const contentClasses = [];
  const mediaClasses = [];
  const innerMediaClasses  = [];
  const marginMediaClasses  = [];
  const mediaAnimateProp = [];
  const mediaAnimateValue = [];

  if(reverse){
    contentClasses.push('order-first md:order-last lg:col-start-7');
    mediaClasses.push('order-last md:order-first');
    mediaAnimateValue.push(-30)
  } else {
    contentClasses.push('order-first lg:col-start-2');
    mediaClasses.push('order-last lg:col-start-7');
    mediaAnimateValue.push(30)
  }

  if(mediaPosition === 'right'){
    innerMediaClasses.push('ml-auto -mr-8 md:mr-[-62px] md:translate-x-[62px]')
    marginMediaClasses.push('!mr-0')
    mediaAnimateProp.push('x');
  } else if (mediaPosition === 'bottom'){
    innerMediaClasses.push('mx-auto -mb-[2.5rem] md:mb-[-18rem] xl:-mb-24')
    mediaAnimateProp.push('x');
  } else if (mediaPosition === 'bottomBreak'){
    innerMediaClasses.push('mx-auto -mb-[2.5rem] md:mb-0 lg:mb-0')
    mediaAnimateProp.push('x');
    contentClasses.push(' md:pb-16 lg:pb-[100px]');
  } else{
    innerMediaClasses.push('mx-auto')
    mediaAnimateProp.push('x');
  }

  return (
    <InViewAnimator className={`container space-y-8 md:space-y-0 md:space-x-8
      ${mediaPosition === 'bottomBreak' ? 
      'lg:flex extra-large-start py-[2.5rem] md:pt-20 md:pb-0 xl:pt-[134px]' : 
      'md:flex items-center lg:pt-[20px]'}`}
      inner={(controls) => (
      <>
        <InViewAnimatorBasicItem className={`lg:pl-8 lg:pl-[40px] xl:pl-[60px] w-full ${contentClasses.join()}`}
         controls={controls}
         delay={1/4}
         duration={1.2}
         prop={'y'}
         start={'30'}>
          <TextContent innerOnly {...mastheadContent} />
        </InViewAnimatorBasicItem>
        <InViewAnimatorBasicItem className={`w-full ${mediaClasses}`}
         controls={controls}
         delay={2/4}
         duration={1.2}
         prop={mediaAnimateProp}
         start={mediaAnimateValue}>
          <div className={`sm:w-3/4 md:w-full ${innerMediaClasses}`}>
            <SimpleMedia simpleMedia={mediaItem.simpleMedia} marginClasses={marginMediaClasses} />
          </div>
        </InViewAnimatorBasicItem>
      </>
    )}
    />
  );
};