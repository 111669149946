import React from "react";
import Image from "~/components/elements/Image";
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const TransitionMasthead = ({
  textGroups
}) => {

  return(
    <InViewAnimator className="container text-center py-20 md:py-40 lg:py-[280px] space-y-8 md:space-y-32"
      inner={(controls) => (
        <>
        {textGroups.map((group, i) => (
          <InViewAnimatorBasicItem className="t-62 mx-auto max-w-[875px] leading-[1.1] font-heading" key={i}
            controls={controls}
            delay={i/2}
            duration={.7}>
            {group.content.map((content, j) => (
              <span key={j}>
                {content.text}
              {content.icon &&
                <InViewAnimatorBasicItem className="w-[40px] lg:w-[70px] inline-flex translate-y-[10px] mx-2"
                  controls={controls}
                  delay={((i + i) + j)/2}
                  duration={.5}
                  prop='scale'
                  start={0}
                  end={1}>
                  <Image image={content.icon} className={`w-full h-full object-contain object-center`}/>
                </InViewAnimatorBasicItem>
              }
              </span>
             ))}
          </InViewAnimatorBasicItem>
        ))}
        </>
      )}
    />
  );
};