import React from 'react';
import Image from '../../elements/Image';
import { TextContent } from '../TextContent';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const CustomerStoriesMasthead = (props) => {
  const { customerStoriesHeading } = props
  return (
    <InViewAnimator className="container py-10 lg:pt-24 lg:pb-20 flex justify-center text-center mx-auto"
      inner={(controls) => (
        <InViewAnimatorBasicItem
          controls={controls}
          delay={.3}
          duration={1}>
          <TextContent innerOnly {...customerStoriesHeading} />
        </InViewAnimatorBasicItem>
      )}
    />
  )
}