import React, { useState } from 'react';
import { useResources } from '../../hooks/useResources';

import { ArticleItem } from '../../templates/article';
import { Link } from 'gatsby';
import Image from '~/components/elements/Image';

import { ButtonArrow } from '../elements/buttons/Button';

import { VideoPlay } from '../elements/Icon';

export const CategoryList = ({ category, layout }) => {
  let resources = useResources();

  // Return Items If they match selected Category
  resources = resources.filter((listItem) => {
    let match = false;
    listItem?.categories?.nodes.map((name) => {
      if (name.name == category?.name) match = true;
    });
    return match;
  });

  const allResources = resources;
  const [amount, setPage] = useState(0);
  const perPage = 4;
  const initialAmount = layout === '3' ? 6 : 4;
  const endVisible = initialAmount + amount * perPage;
  const showResources = resources?.slice(0, endVisible);

  return (
    <>
      {/* Title and show all */}
      <div className="container">
        <div className="flex flex-col md:flex-row justify-center items-center md:justify-between space-y-6 md:space-y-0 md:space-x-8">
          <h2 className="text-h2">{category?.name}</h2>
          <div className="hidden md:block">
            {endVisible < allResources?.length ? (
              <button
                className="btn btn-coral flex items-center space-x-[9px] whitespace-nowrap justify-center group"
                onClick={() => {
                  setPage(amount + 2);
                }}
              >
                <span>See all {category?.name}</span>
                <span className="inline-block group-hover:translate-x-[6px] transition-all duration-200">
                  <ButtonArrow color={'coral'} />
                </span>
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      {/* listings & Variants */}
      {layout === '3' ? (
        <div className="container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-[24px] sm:gap-[24px] card-opacity">
          {showResources.map((article, i) => (
            <ArticleItem article={article} key={i} />
          ))}
        </div>
      ) : (
        <div className="container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-y-[24px] sm:gap-[24px] card-opacity">
          {showResources.map((article, i) => (
            <ArticleSmall article={article} key={i} category={category?.name} />
          ))}
        </div>
      )}
      {/* Show All button for mobile devices */}
      <div className="container flex justify-center md:hidden">
        {endVisible < allResources?.length ? (
          <button
            className="btn btn-coral flex items-center space-x-[9px] whitespace-nowrap justify-center group"
            onClick={() => {
              setPage(amount + 2);
            }}
          >
            <span>See all {category?.name}</span>
            <span className="inline-block group-hover:translate-x-[6px] transition-all duration-200">
              <ButtonArrow color={'coral'} />
            </span>
          </button>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export const ArticleSmall = ({ article, category }) => {
  return (
    <Link to={article?.uri} className="hover:opacity-[.6] transition-all duration-200 group flex flex-col flex-auto">
      <div className="relative w-full aspect-[14/9] rounded-[16px] overflow-hidden safari-overflow">
        <Image
          image={article?.postTypePost?.summary?.image}
          objectFit={`cover`}
          className={`w-full h-full object-cover object-center`}
        />
        {category === 'Videos' && (
          <div className="absolute inset-0 m-auto z-10 w-[58px] h-[58px] group-hover:scale-[1.1] transition-all duration-200">
            <VideoPlay />
          </div>
        )}
      </div>
      <div className="flex flex-col flex-auto">
        <h3 className="t-24-h line-clamp-2 mb-4 mt-6 md:mt-[24px]">{article?.title}</h3>
        {category === 'Videos' ? (
          <span className="t-16 text-coral font-bold underline mt-6">Watch now</span>
        ) : (
          <>
            <p
              className="t-14 line-clamp-2 mb-6 md:mb-[24px]"
              dangerouslySetInnerHTML={{ __html: article?.postTypePost?.summary?.summary }}
            />
            <span className="t-16 text-coral font-bold underline mt-auto">Read Story</span>
          </>
        )}
      </div>
    </Link>
  );
};
