import React from 'react';
import { Link } from 'gatsby';
import Image from '../elements/Image';
// import { Button } from '../elements/buttons/Button';

export function FunnelDiagram({ funnelDiagram }) {
  const { topRow, bottomRow, topButton, bottomButton, image } = funnelDiagram;

  return (
    <div className="container !max-w-[1280px] flex flex-col items-center space-y-8 md:space-y-5">
      <Row items={topRow} type="top" />
      <Link to={topButton?.url} className="btn btn-orange flex items-center space-x-2">
        <span>{topButton?.title}</span>
        <Arrow />
      </Link>

      <div className="w-full max-w-[384px] py-2">
        <Image image={image} />
      </div>

      <Link to={bottomButton?.url} className="btn btn-orange flex items-center space-x-2">
        <span>{bottomButton?.title}</span>
        <Arrow />
      </Link>
      <Row items={bottomRow} type="bottom" />
    </div>
  );
}

function TopLine({ type = 'top' }) {
  return (
    <svg
      fill="none"
      height="100%"
      viewBox="0 0 840 76"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: type === 'bottom' ? 'rotate(180deg)' : 'none' }}
    >
      <g opacity=".2">
        <path d="m420 12v60" stroke="#071b1e" />
        <path d="m828 22v3c0 13.2548-10.745 24-24 24h-360c-13.255 0-24 10.7452-24 24v3" stroke="#071b1e" />
        <path d="m12 22v3c0 13.2548 10.7452 24 24 24h360c13.255 0 24 10.7452 24 24v3" stroke="#071b1e" />
      </g>
      <g fill="#fff" stroke="#CBCECE" stroke-width="8">
        <circle cx="12" cy="12" r="8" />
        <circle cx="420" cy="12" r="8" />
        <circle cx="828" cy="12" r="8" />
      </g>
    </svg>
  );
}

function Arrow() {
  return (
    <svg viewBox="0 0 20 20" width="20" height="auto" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 6l4 4m0 0l-4 4m4-4H5"
        stroke="#fff"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
function Row({ items, type = 'top' }) {
  return (
    <div className="flex flex-col mt-2 md:mt-0">
      <div className="grid md:grid-cols-3 gap-y-10 gap-x-6 text-center w-full">
        {items &&
          items.map((item, i) => {
            return (
              <div key={`row-item${item.heading}`}>
                <div className="max-w-[400px] mx-auto w-full">
                  <h4 className="t-28 mb-5" dangerouslySetInnerHTML={{ __html: item.heading }} />
                  <div
                    className="t-18 text-[#071B1EB2] leading-[1.63]"
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  />
                </div>
              </div>
            );
          })}
      </div>
      <div className={`hidden md:block mx-auto w-[70%] ${type === 'bottom' ? 'order-[-1] mb-8' : 'mt-4'}`}>
        <TopLine type={type} />
      </div>
    </div>
  );
}
