import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Form } from './forms/Form';
import { ApplicationForm } from './forms/ApplicationForm';
import { Close } from './Icon';

export const ModalDialog = ({ role, form, isOpen, setIsOpen, }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} as="div"  className="fixed inset-0 z-10 overflow-y-auto px-6">
        <div className="flex items-center justify-center min-h-screen">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
          </Transition.Child>
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
            <div className="relative bg-white rounded-[2rem] max-w-[830px] w-full mx-auto p-8 md:px-[70px] md:pt-[68px] md:pb-[60px] mt-12 text-center">
              <button onClick={() => setIsOpen(false)} 
                className='w-8 h-8 md:w-[50px] md:h-[50px] absolute top-[12px] md:top-[14px] right-[12px] md:right-[1rem] hover:opacity-50 hover:scale-[1.1] transition-all duration-200'>
                <Close/>
              </button>
              {form === 'apply' ?
                <>
                  <Dialog.Title className="t-40 text-left mb-[10px]">Apply now</Dialog.Title> 
                  <p className='t-16 max-w-[380px] ml-0 text-left mb-8 md:mb-[42px]'>{role}</p>
                  <ApplicationForm role={role}/>
                </>
                :
                <>
                  <Dialog.Title className="t-40 text-left mb-[10px]">Get in touch.</Dialog.Title> 
                  <p className='t-16 max-w-[380px] ml-0 text-left mb-8 md:mb-[42px]'>Ask questions, learn more about our solution, and get started in seconds.</p>
                  <Form/>
                </>
              }
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
