import React from 'react';
import { TextContent } from './TextContent';
import CountUp, { useCountUp } from 'react-countup';
import useScrollSpy from 'react-use-scrollspy';

export const Stats = ({ statsContent, stats, layout, columnStats }) => {
  function getClassNames(layout) {
    if (layout === '3-cols') return '';
    if (layout === '3-cols-masthead') return '';

    return 'grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-x-8 lg:py-8 xl:py-[40px]';
  }

  return (
    <div className={`container ${getClassNames(layout)}`}>
      {statsContent?.heading || statsContent?.content ? (
        <div className={`col-span-12 md:col-span-6 lg:pl-12`}>
          <TextContent innerOnly {...statsContent} />
        </div>
      ) : null}

      {layout === 'list' && <StatList stats={stats} />}
      {layout === 'grid' && <StatGrid stats={stats} />}
      {layout === '3-cols' ? <ThreeColStats stats={columnStats} /> : null}
      {layout === '3-cols-masthead' ? <ThreeColStatMasthead stats={columnStats} /> : null}
    </div>
  );
};

export const ThreeColStats = ({ stats = [] }) => {
  return (
    <div className="grid md:grid-cols-3 gap-8 lg:gap-12 max-w-[1200px] mx-auto  md:text-center ">
      {stats.map((stat, i) => (
        <div key={`stat${i}`}>
          <div className="w-full md:max-w-[20rem] mx-auto flex md:block gap-4 md:gap-0">
            <div className="font-heading text-midGreen flex-[0_0_30%] sm:flex-[0_0_33%]">
              <div className="hidden sm:block t-66 leading-none mb-2">{stat.topHeading}</div>
              <div className="sm:hidden t-48 leading-none">{stat.topHeading}</div>

              <div
                className="hidden sm:block t-30 leading-none"
                dangerouslySetInnerHTML={{ __html: stat.topContent }}
              />
              <div className="sm:hidden t-26 leading-none" dangerouslySetInnerHTML={{ __html: stat.topContent }} />
            </div>

            <div className="w-[1px] bg-[#B2BCBD] md:hidden flex-[0_0_1px] mx-2"></div>

            <div className="my-6 h-[1px] w-full bg-[#B2BCBD] hidden md:block"></div>

            <div className="text-dark flex-auto">
              <div className="text-dark t-24 font-medium mb-2">{stat.bottomHeading}</div>
              <div className="text-dark t-18 text-opacity-70">{stat.bottomContent}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export const ThreeColStatMasthead = ({ stats = [] }) => {
  return (
    <div className="border-white border-opacity-10 border-t-[1px] pt-12 md:pt-20 mt-20 md:mt-32">
      <div className="grid md:grid-cols-3 gap-8 lg:gap-12 max-w-[1200px] mx-auto  md:text-center ">
        {stats.map((stat, i) => (
          <div key={`stat${i}`}>
            <div className="w-full md:max-w-[20rem] mx-auto flex md:block">
              <div className="font-heading text-[#A8F0DC] flex-[0_0_30%] sm:flex-[0_0_33%]">
                <div className="hidden sm:block t-66 leading-none mb-2">{stat.topHeading}</div>
                <div
                  className="hidden sm:block t-18 text-white leading-none"
                  dangerouslySetInnerHTML={{ __html: stat.topContent }}
                />
                <div
                  className="sm:hidden t-18 text-white leading-none"
                  dangerouslySetInnerHTML={{ __html: stat.topContent }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const StatList = ({ stats }) => {
  return (
    <div className={`col-span-12 md:col-span-6 lg:col-span-5 space-y-6 md:space-y-8 lg:space-y-[50px]`}>
      {stats.map((statItem, i) => (
        <div key={i} className="text-center">
          <div className="text-h2 mb-1">
            <span>{statItem.stat.preAppend}</span>
            <CountUp end={statItem.stat.stat} duration={3} />
            <span className="ml-2">{statItem.stat.append}</span>
          </div>
          <p>{statItem.caption}</p>
        </div>
      ))}
    </div>
  );
};

export const StatGrid = ({ stats }) => {
  return (
    <div className={`col-span-12 md:col-span-6`}>
      <div className="border border-altGrey rounded-2xl max-w-[580px] mx-auto grid grid-cols-1 grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-y-6 md:gap-y-8 lg:gap-y-16 px-4 md:px-6 lg:px-8 py-8 md:py-12 lg:pt-16 lg:pb-[40px]">
        {stats.map((statItem, i) => (
          <div key={i} className="text-center">
            <div className="text-h2 mb-1 text-coral">
              <span>{statItem.stat.preAppend}</span>
              <CountUp end={statItem.stat.stat} duration={3} />
              <span className="ml-2">{statItem.stat.append}</span>
            </div>
            <p className="font-bold max-w-[240px] mx-auto">{statItem.caption}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
