import React, { useState } from 'react';
import { Link } from 'gatsby';
import Image from '~/components/elements/Image';
import { AnimatePresence, motion } from 'framer-motion';

export const InternalNavigation = ({ menuItem }) => {
  const pathname = typeof window !== 'undefined' && window.location.pathname;

  return (
    <>
      <div className="hidden lg:flex mt-24 justify-between  bg-darkTaupe px-[6px] py-[4px] rounded-2xl section-contained internal-nav">
        {menuItem.map((item, i) => (
          <Item key={i} item={item} pathname={pathname} i={i} />
        ))}
      </div>
      <div className="block lg:hidden" />
    </>
  );
};

export const Item = ({ item, pathname, i }) => {
  const bgColour = [];
  const textColour = [];
  const activeBgColour = [];
  let activeTextColour;
  const iconClass = [];
  let activeIcon;

  const [hover, setHover] = useState(false);

  if (item.backgroundColour === 'darkGreen') {
    bgColour.push('hover:bg-darkGreen ');
    // textColour.push('hover:text-darkGreen');
    activeBgColour.push('bg-darkGreen');
    activeTextColour = '#FFFFFF';
    // iconClass.push('group-hover:invert');
    activeIcon = 'invert(1)';
  } else if (item.backgroundColour === 'maroon') {
    bgColour.push('hover:bg-maroon ');
    // textColour.push('hover:text-white');
    activeBgColour.push('bg-maroon');
    activeTextColour = '#FFFFFF';
    // iconClass.push('group-hover:invert');
    activeIcon = 'invert(1)';
  } else if (item.backgroundColour === 'midGreen') {
    bgColour.push('hover:bg-midGreen ');
    // textColour.push('hover:text-white');
    activeBgColour.push('bg-midGreen ');
    activeTextColour = '#FFFFFF';
    // iconClass.push('group-hover:invert');
    activeIcon = 'invert(1)';
  } else if (item.backgroundColour === 'darkBlue') {
    bgColour.push('hover:bg-darkBlue ');
    // textColour.push('hover:text-white');
    activeBgColour.push('bg-darkBlue ');
    activeTextColour = '#FFFFFF';
    // iconClass.push('group-hover:invert');
    activeIcon = 'invert(1)';
  } else if (item.backgroundColour === 'lightGreen') {
    bgColour.push('hover:bg-lightGreen ');
    activeBgColour.push('bg-lightGreen');
  } else if (item.backgroundColour === 'blue') {
    bgColour.push('hover:bg-blue ');
    activeBgColour.push('bg-blue ');
  }

  return (
    <motion.div
      onHoverStart={() => setHover(true)}
      onHoverEnd={() => setHover(false)}
      animate={{
        color: item?.link?.url === pathname ? activeTextColour : `#000000`,
        opacity: 1,
      }}
      transition={{ duration: 0.4, delay: 0, opacity: { duration: 0.1, delay: 0 } }}
    >
      <Link
        to={item?.link?.url}
        className={` internal-item transition-all duration-300 rounded-[66px] flex flex-col  xl:flex-row items-center justify-center xl:justify-between px-5 py-[12px] group relative  `}
      >
        <AnimatePresence>
          {item?.link?.url === pathname && (
            <motion.div
              key={`menuBg${i}`}
              layoutId={`menuBg`}
              transition={{ duration: 0.5 }}
              className={`absolute inset-0 ${bgColour} ${
                item?.link?.url === pathname && `${activeBgColour} `
              } rounded-[66px] overflow-hidden `}
            ></motion.div>
          )}
        </AnimatePresence>

        <motion.div
          animate={{ filter: item?.link?.url === pathname ? activeIcon : null }}
          transition={{ duration: 0.4, delay: 0.1 }}
          className={`relative pointer-events-none  w-[20px] h-[20px] overflow-hidden mr-2 ${iconClass} `}
        >
          <Image image={item.icon} className={`w-full h-full object-contain object-center pointer-events-none`} />
        </motion.div>
        <span className="relative md:text-center xl:text-left leading-[1] mt-2 xl:mt-0">{item?.link?.title}</span>
      </Link>
    </motion.div>
  );
};
