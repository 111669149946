import React from "react";
import Marquee from "react-fast-marquee";
import Image from "~/components/elements/Image";
import { PropertyCard } from "~/components/flexible/marqueeCards/PropertyCard";
import { CustomerCard } from "~/components/flexible/marqueeCards/CustomerCard";
import { WordCard } from "~/components/flexible/marqueeCards/WordCard";
import { ActivityCard } from "~/components/flexible/marqueeCards/ActivityCard";

export const FlexMarquee = ({ 
  layout,
  border,
  marqueeItems,
  }) => {
  
  const borderClasses =[];

  let cardAmount = marqueeItems.length;
    
  if(border){
    borderClasses.push(`border border-fadedGrey`)
  }
  return(
    <div className="">
      <div className={`container rounded-[2rem] !px-0 py-6 md:py-8 ${borderClasses}`}>
      {layout === 'feed' &&
      <div className="w-full border-b border-fadedGrey pb-5 px-6 md:px-8 -mt-2 flex items-center space-x-4">
        <h4 className="p-large font-body font-bold text-xs">Setpoint Activity Feed</h4><span className="bg-coral w-[26px] h-[26px] flex justify-center items-center rounded-full text-white text-xs">{cardAmount}</span>
      </div>
      }
        <Marquee gradient={false} className='items-center'>
          {marqueeItems.map((card, i) => (
            <div key={i}>
              <>
              {layout === 'property' && <PropertyCard card={card.propertyCard} delayProp={i}/>}
              {layout === 'customer' && <CustomerCard card={card.customerCard} delayProp={i} />}
              {layout === 'words' && <WordCard card={card.wordCard} delayProp={i}/>}
              {layout === 'feed' && <ActivityCard card={card.activityFeed} delayProp={i}/>}
              </>
            </div>
          ))}
        </Marquee>
      </div>
    </div>
  );
};
