import React, {useState} from "react";
import { ButtonArrow } from "../../elements/buttons/Button";
import Image from "../../elements/Image";
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { ModalDialog } from '~/components/elements/ModalDialog';


export const ContactGroup = ({
  iconGrid,
  socialMedia,
}) => {

  return(
    <>
      <InViewAnimator className="container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-x-6 lg:gap-x-[24px]"
        inner={(controls) => (
          <> 
          {iconGrid.iconGrid.map((item, i) => (
            <GridItem key={i} item={item} staggerDelay={i} controls={controls}/>
          ))}
          </>
        )}
      />
      <InViewAnimator className="container text-center pt-8 md:pt-16 md:pb-8"
        inner={(controls) => (
          <> 
            <div>
              <h2 className="text-h2 mb-[15px]">{socialMedia.heading}</h2>
              <p className="t-18 mx-auto max-w-[280px]" dangerouslySetInnerHTML={{
                  __html: socialMedia.description,
                }}/>
              <div className="flex justify-center space-x-4 md:space-x-8 mt-8 md:mt-[42px]">
                {socialMedia.socialMediaMenu.map((item ,i) => (
                  <SocialItem key={i} item={item} staggerDelay={i} controls={controls}/>
                ))}
              </div>
            </div>
          </>
        )}
      />
    </>
  );
};

export const GridItem = ({
  item,
  staggerDelay,
  controls
}) => {

  const [isOpen, setIsOpen] = useState(false);

  return(
    <InViewAnimatorBasicItem className="bg-white text-darkGreen rounded-[20px] px-6 md:px-[36px] py-8 md:py-[44px] text-center group cursor-pointer hover:!scale-[1.04] transition-all duration-300"
      controls={controls}
      delay={staggerDelay/4}
      duration={.5}
      key={staggerDelay}> 
      <button onClick={() => setIsOpen(true)} className=''>
        <div className="w-24 h-24 md:w-[134px] md:h-[134px] mx-auto">
          <Image image={item.icon} className={`w-full h-full object-contain object-center`} />
        </div>
        <h4 className="t-30 mt-4 md:mt-6">{item.heading}</h4>
        <div className="flex justify-center mt-6 md:mt-8 group-hover:translate-x-2 transition-all duration-300">
          <ButtonArrow arrowFill={true} />
        </div>
        <ModalDialog isOpen={isOpen} setIsOpen={setIsOpen} form={'contact'} />
      </button>
    </InViewAnimatorBasicItem>
  );
};


export const SocialItem = ({
  item,
  staggerDelay,
  controls
}) => {

  return(
    <InViewAnimatorBasicItem 
      controls={controls}
      delay={staggerDelay/4}
      duration={1}
      key={staggerDelay}> 
        <a href={item?.link?.url} target={item?.link?.target} className='block hover:!scale-[1.2] transition-all duration-300'>
          <div className="w-[24px] h-[24px]">
            <Image image={item.icon} className={`w-full h-full object-contain object-center`} />
          </div>
        </a>
    </InViewAnimatorBasicItem>
  );
};