import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';

export const useCareerListings = () => {
  const [errorMessage, setErrorMessage] = useState(null);

  const getGraphJobs = () => {
    const data = useStaticQuery(graphql`
      query {
        allWpRole {
          nodes {
            title
            uri
            postTypeRoles {
              location
            }
          }
        }
      }
    `);

    return data.allWpRole?.nodes;
  };

  const getGreenhouseJobs = async () => {
    const response = await fetch('https://boards-api.greenhouse.io/v1/boards/setpoint/jobs?content=true');
    const jobs = await response.json();

    return jobs;
  };

  const values = React.useMemo(
    () => ({
      errorMessage,
      getGraphJobs,
      getGreenhouseJobs,
    }),
    []
  );

  return values;
};
