import React from 'react';
import { ContentRenderer } from '../ContentRenderer';

export const Section = ({
  prefix,
  innerSpacing,
  backgroundColor,
  previousBackground,
  nextBackground,
  paddingTopControls,
  paddingBottomControls,
  noPaddingTop,
  noPaddingBottom,
  containsStickyComponent,
  size,
  classname,
  content = [],
  components = [],
  sectionStyle,
  clearHeader,
}) => {
  if (!content.length) content = components;

  const classes = ['section'];

  if (previousBackground !== backgroundColor) {
    classes.push('section-top');
  }
  if (nextBackground !== backgroundColor) {
    classes.push('section-bot');
  }

  if(!containsStickyComponent){
    classes.push('contain-paint');
  }

  if (backgroundColor === 'white') {
    classes.push('bg-white !text-darkGreen');
  } else if (backgroundColor === 'darkGreen') {
    classes.push('bg-darkGreen !text-white');
  } else if (backgroundColor === 'maroon') {
    classes.push('bg-maroon !text-white');
  } else if (backgroundColor === 'lightTaupe') {
    classes.push('bg-lightTaupe text-dark');
  } else if (backgroundColor === 'taupe') {
    classes.push('bg-taupe text-dark');
  } else if (backgroundColor === 'darkTaupe') {
    classes.push('bg-darkTaupe !text-darkGreen');
  } else if (backgroundColor === 'darkestGreen') {
    classes.push('bg-darkestGreen text-white');
  } else if (backgroundColor === 'midGreen') {
    classes.push('bg-midGreen !text-white');
  } else if (backgroundColor === 'darkBlue') {
    classes.push('bg-darkBlue !text-white');
  } else if (backgroundColor === 'lightGreen') {
    classes.push('bg-lightGreen !text-darkGreen');
  } else if (backgroundColor === 'blue') {
    classes.push('bg-blue !text-darkGreen');
  } else if (backgroundColor === 'secFadedGreen') {
    classes.push('bg-secFadedGreen !text-white');
  }

  if (innerSpacing === '40') {
    classes.push('space-y-12 md:space-y-16 lg:space-y-24 xl:space-y-40');
  } else if (innerSpacing === '12') {
    classes.push('space-y-8 md:space-y-10 xl:space-y-12');
  } else if (innerSpacing === '8') {
    classes.push('space-y-6 md:space-y-8');
  } else if (innerSpacing === '2') {
    classes.push('space-y-2');
  } else if (innerSpacing === 'none') {
    classes.push('space-y-0');
  } else {
    classes.push('space-y-8 md:space-y-12 xl:space-y-20');
  }

  if(clearHeader) {
    classes.push('!mt-20 md:!mt-32');
  }

  if (size === '0' || size === null) {
    classes.push('section-0');
  }

  // Padding Controlls
  if(paddingTopControls === 'small'){
    classes.push('!pt-4 md:!tb-12');
  } else if(paddingTopControls === 'larger'){
    classes.push('!pt-16 md:!pt-[6.5rem]');
  }

  if(paddingBottomControls === 'small'){
    classes.push('!pb-4 md:!pb-12');
  } else if(paddingBottomControls === 'larger'){
    classes.push('!pb-16 md:!pb-[6.5rem]');
  }

  if (noPaddingTop) {
    classes.push('!pt-0');
  }
  if (noPaddingBottom) {
    classes.push('!pb-0');
  }

  let layouts = 'multiple';
  if (content && content.length === 1) {
    layouts = content[0].fieldGroupName.replace(prefix, '');
  }

  if (layouts === 'MastheadAbout') {
    classes.push('overflow-hidden');
  }

  // Controls Section Type
  if (sectionStyle === 'contained'){
    classes.push('section-contained')
  }

  if (classname) {
    classes.push(classname);
  }

  return (
    <section data-layouts={layouts} data-background={backgroundColor} className={classes.join(' ')}>
      <ContentRenderer prefix={prefix} content={content} />
    </section>
  );
};
