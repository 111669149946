import { Link } from 'gatsby';
import React from 'react';
import Image from '../../elements/Image';
import { TextContent } from '../TextContent';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import TickCircle from '~/assets/images/tick-circle.svg';

function V2({ heading, content, button, bulletPoints=[], images=[]}) {


  return (
    <section className="py-8 sm:pb-10 sm:pt-16 md text-white relative">
      <div className="container h-full flex flex-col items-center justify-center">
        {/* text */}
        <div className="text-center mx-auto max-w-[696px] space-y-5 md:space-y-6">
          <h1 className="text-h1 t-72 md:max-w-[38rem] mx-auto lg:max-w-[43rem]" dangerouslySetInnerHTML={{ __html: heading }}/>
          <div className="max-w-[540px] lg:max-w-[588px] t-20 mx-auto" dangerouslySetInnerHTML={{ __html: content }} />
          <div className="pt-3">
            <Link to={button.url} className="btn btn-orange inline-block">{button?.title}</Link>
          </div>
        </div>

        {/* bullets  */}
        <div className="mt-14 md:mt-[65px] ">
          <div className="flex flex-wrap justify-center  -ml-8 lg:-ml-12 -mb-6">
            {bulletPoints.map((bullet, i) => (
              <div key={i} className="pl-8 lg:pl-12 pb-6 flex items-center md:items-start space-x-3 md:space-x-4">
                <div className="flex items-center justify-center w-[24px] h-[24px] rounded-full ">
                  <img src={TickCircle} alt="" />
                </div>
                <div className="text-center md:text-left">
                  <div className="">{bullet.text}</div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Images */}
        {/* <div className="absolute inset-0 hidden md:block pointer-events-none">
          <div className="container h-full">
            <div className="relative h-full">
              {images.map((img, i) => {
                return (
                  <div key={`image-${i}`} className={`absolute w-full ${img.className || ''}`}>
                    <Image image={img.image} className=" w-full h-full object-cover" />
                  </div>
                )
              })}
            </div>
          </div>
        </div> */}

      </div>
    </section>
  )
}

const HomeMasthead = (props) => {
  const { homeMastheadContent, image, layout="v1" } = props;

  return !layout || layout === 'v1' ? (
    <InViewAnimator className="container md:flex justify-between w-full pt-16 md:pt-32 lg:pt-[193px] md:space-x-6 "
      inner={(controls) => (
        <>
          <InViewAnimatorBasicItem className="w-full md:max-w-[641px] z-[10] "
            controls={controls}
            delay={1/4}
            duration={1.2}
            prop={'y'}
            start={'30'}>
              <TextContent innerOnly {...homeMastheadContent} />
          </InViewAnimatorBasicItem>
          <InViewAnimatorBasicItem className="w-full md:max-w-[555px] mx-auto md:mx-0 mt-12 md:mt-0 z-[0]"
            controls={controls}
            delay={2/4}
            duration={1.2}
            prop={'x'}
            start={'30'}>
              <Image image={image} className={`md:mr-[-234px] lg:mr-[-140px] xl:ml-[-100px] lg:-mt-8 home-masthead-margin-left`} />
          </InViewAnimatorBasicItem>
        </>
      )}
    />
  ):(<V2 {...props?.v2} />);
};

export default HomeMasthead;
