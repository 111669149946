import { stat } from "fs";
import React from "react";
import CountUp, { useCountUp } from 'react-countup';

export const LargeStats = ({
  stats,
  borderStyle,
  statColour,
  largerWidth,
}) => {

  // Border Controls
  const borderClass = [];

  if(borderStyle === 'dark'){
    borderClass.push('border-darkGreen');
  } else if(borderStyle === 'mid'){ 
    borderClass.push('border-darkTaupe');
  } else {
    borderClass.push('border-white-30');
  }

  // Stat Colour Controls
  const statClass = [];

  if(statColour === 'lightGreen'){
    statClass.push('text-lightGreen')
  } else if(statColour === 'darkGreen'){
    statClass.push('text-darkGreen')
  } else if(statColour === 'midGreen'){
    statClass.push('text-midGreen')
  } else {
    statClass.push('text-white')
  }


  return (
    <div className={`${largerWidth ? '' : 'container'}`}>
     <Stats borderClasses={borderClass} statClasses={statClass} stats={stats}/>
    </div>
  );
};

export const Stats = ({
  stats,
  borderClasses,
  statClasses,
}) => {

  return(
    <div>
      <div className={`border ${borderClasses} rounded-[2rem] mx-auto grid grid-cols-1 md:grid-cols-3 gap-y-6 md:gap-y-0 md:gap-x-8 px-4 md:px-6 lg:px-8 py-8 md:py-12 lg:py-[40px]`}>
        {stats.map((statItem, i) => (
          <div key={i} className="text-center">
            <div className={`font-heading t-80 mb-1 ${statClasses}`}>
              <span>
                {statItem.stat.preAppend}
              </span>
              {statItem?.stat?.addStartValue ?
                <CountUp 
                  start={statItem?.stat?.startValue}
                  end={statItem?.stat?.stat}
                  duration={3}
                  delay={0}>
                  {({ countUpRef }) => (
                      <span ref={countUpRef} />
                  )}
                </CountUp>
              :
                <CountUp
                  end={statItem?.stat?.stat}
                  duration={3}
                />
              }
              <span className="ml-2">
                {statItem?.stat?.append}
              </span>
            </div>
            <p className="font-medium max-w-[240px] mx-auto md:-mt-6">{statItem?.caption}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
