import React from "react";
import Image from "../../elements/Image";

export const GridCards = ({
  columnOne,
  columnTwoContent,
  cardsColour,
}) => {

  const cardClasses = ['rounded-[2rem] border border-borderGrey '];
  if(cardsColour === 'darkGreen'){
    cardClasses.push(' bg-darkGreen text-white');
  } else if(cardsColour === 'maroon'){
    cardClasses.push(' bg-maroon text-white');
  } else if(cardsColour === 'darkTaupe'){
    cardClasses.push(' bg-darkTaupe');
  } else if(cardsColour === 'darkBlue'){
    cardClasses.push(' bg-darkBlue text-white');
  } else if(cardsColour === 'blue'){
    cardClasses.push(' bg-blue');
  } else if(cardsColour === 'midGreen'){
    cardClasses.push(' bg-midGreen');
  } else if(cardsColour === 'white'){
    cardClasses.push(' bg-white text-darkGreen');
  }

  return(
    <div className="container overflow-auto grid grid-cols-1 md:grid-cols-2 gap-y-8 md:gap-y-0 md:gap-x-[24px]">
      <LargeCard cardClasses={cardClasses} content={columnOne} />
      <div className="space-y-4">
        {columnTwoContent.map(( card, i) => (
          <SmallCards key={i} cardClasses={cardClasses} content={card.content} />
        ))}
      </div>
    </div>
  );
}

export const LargeCard = ({
  content,
  cardClasses
}) => {

  return(
    <div className={` ${cardClasses} px-8 py-10 md:py-[46px] md:pr-[54px] md:pl-[58px] text-center flex flex-col justify-center`}>
       <div className="w-[125px] h-[145px] md:w-[142px] md:h-[170px] mx-auto mb-6 md:mb-8">
        <Image image={content.icon} className={`w-full h-full object-contain object-center`} />
      </div>
      <h2 className="t-40 max-w-[390px] mx-auto" dangerouslySetInnerHTML={{
              __html: content.heading,
            }}/>
    </div>
  );
};

export const SmallCards = ({
  content,
  cardClasses
}) => {

  return(
    <div className={` ${cardClasses} px-8 py-10 md:py-[46px] md:pr-[54px] md:pl-[58px]`}>
      <p className="t-20" dangerouslySetInnerHTML={{
              __html: content,
            }}/>
    </div>
  );
};