import React from 'react';
import { useDate } from '~/hooks/useDate';

export const LegalMasthead = (props) => {
  const { heading, updateDate } = props
  const [date] = useDate(updateDate)

  return (
    <div className="container !max-w-[1200px] pt-8 md:pt-36">
      <h1 className="t-70 mb-8">{ heading }</h1>
      <h2 className="t-20 leading-[1.5] font-body">Last Updated: {updateDate}</h2>
    </div>
  )
}