import React from 'react';
import Image from '~/components/elements/Image';
import yellowShape from '~/assets/images/yellow-shape.svg';
import quoteMark from '~/assets/images/quote-mark.svg';
import { motion } from 'framer-motion';

export const BlockQuoteCardDark = (props) => {
  const caseStudyItem = props?.caseStudyItem[0]
  const summary = caseStudyItem?.postTypeCaseStudy?.caseStudySummary

  return (
    <div className="container">
      <div className="bg-darkTaupe rounded-4xl flex gap-4 items-center flex-col md:flex-row p-4 lg:py-12 lg:px-24">
        <div className="md:max-w-[50%] lg:max-w-[585px]">
          <Image className="w-[125px] mb-9" image={summary.logo}/>
          <span className="font-heading t-24 block mt-8 mb-9 leading-[1.1]" dangerouslySetInnerHTML={{__html: summary?.quote}}/>
          <cite className="not-italic font-body"><span className="font-medium">{summary?.name}</span> {` — ${summary.role} at ${summary.company}` }</cite>
        </div>
        <div className="flex-auto">
          <div className="flex justify-center mx-16 min-w-full lg:min-w-[260px]">
            <div className="pl-10 relative">
              <img className="w-full" src={yellowShape} alt=""/>
              <motion.img
                initial={{x: -100, opacity: 0}}
                whileInView={{x: 0, opacity: 1, transition: { duration: 0.7}}}
                className="absolute bottom-10 left-0" src={quoteMark}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}