import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Image from '~/components/elements/Image';
import { HeightFixer } from '~/components/elements/Animator';

export function TabAccordion({ tabs = [], heading, content }) {
  const { ref, inView } = useInView();

  const [activeTab, setActiveTab] = useState(tabs[0]?.heading);
  const [activeAccordionItemNumber, setActiveAccordionItemNumber] = useState(-1);
  const [activeItem, setActiveItem] = useState(tabs[0]?.items[0]);

  // Reset the active tab when the tabs change
  useEffect(() => {
    setActiveAccordionItemNumber(0);
  }, [activeTab]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const itemsLength = tabs.filter((tab) => tab.heading === activeTab)[0].items.length;
      setActiveAccordionItemNumber(activeAccordionItemNumber + 1 < itemsLength ? activeAccordionItemNumber + 1 : 0);
    }, 8000);

    return () => timeout && clearTimeout(timeout);
  }, [activeAccordionItemNumber]);

  useEffect(() => {
    setActiveItem(
      tabs.filter((tab) => tab.heading === activeTab)[0].items.filter((item, i) => activeAccordionItemNumber === i)[0]
    );
  }, [activeAccordionItemNumber]);

  useEffect(() => {
    if (inView) {
      setActiveAccordionItemNumber(0);
    }
  }, [inView]);
  return (
    <section ref={ref} className="container !max-w-[1230px] select-none">
      <HeightFixer>
        <div className="grid md:grid-cols-2 gap-12">
          {/* Left */}
          <div>
            <div className="md:max-w-[486px] w-full flex flex-col items-center md:items-start">
              {heading && (
                <div className="mb-4">
                  {heading ? <h2 className="t-57 text-dark mb-4">{heading}</h2> : null}
                  {content ? (
                    <div
                      className="t-18 text-dark text-opacity-70"
                      dangerouslySetInnerHTML={{ __html: content }}
                    />
                  ) : null}
                </div>
              )}
              {tabs?.length > 1 ? (
                <div className="rounded-[10px] bg-darkTaupe p-1.5 space-x-2 mb-4 inline-flex">
                  {tabs.map((tab) => (
                    <div
                      key={`tab-${tab.heading}`}
                      onClick={() => setActiveTab(tab.heading)}
                      className={`cursor-pointer px-[12px] py-[6px] rounded-[8px] t-18  transition-all duration-200 ease-in-out ${
                        activeTab === tab.heading ? 'bg-white font-medium text-dark' : ''
                      }`}
                    >
                      {tab.heading}
                    </div>
                  ))}
                </div>
              ) : null}

              {tabs.map((tab) => (
                <div className={`${tab.heading === activeTab ? '' : 'hidden'}`} key={`tab-content-${tab.heading}`}>
                  {tab?.items?.length > 0
                    ? tab.items.map((item, i) => {
                        const isActive = activeAccordionItemNumber === i;
                        return (
                          <div
                            key={tab.heading + item.heading}
                            onClick={() => setActiveAccordionItemNumber(i)}
                            className=" py-6 cursor-pointer relative"
                          >
                            <div className="flex space-x-6">
                              <div className="t-20 opacity-30 relative top-[1px]">
                                {i + 1 < 10 ? `0${i + 1}` : i + 1}
                              </div>

                              <div className="flex-auto">
                                <div className="t-22" dangerouslySetInnerHTML={{ __html: item.heading }} />
                                <AnimatePresence exitBeforeEnter={true}>
                                  {isActive ? (
                                    <motion.div
                                      key={tab.heading + item.heading + item.content}
                                      className="t-18 text-dark text-opacity-70"
                                      initial={{ height: 0, opacity: 0 }}
                                      animate={{ height: 'auto', opacity: 1, transition: { opacity: { delay: 0.2 } } }}
                                      exit={{ height: 0, opacity: 0, transition: { opacity: { duration: 0.2 } } }}
                                      transition={{
                                        type: 'tween',
                                        ease: 'easeInOut',
                                      }}
                                    >
                                      <div
                                        className="pt-1 md:pt-2 mr-2"
                                        dangerouslySetInnerHTML={{ __html: item.content }}
                                      ></div>
                                    </motion.div>
                                  ) : null}
                                </AnimatePresence>
                              </div>
                            </div>
                            {/* {isActive ? (

                            ):null} */}

                            <div className="!ml-0 absolute inset-x-0 w-full h-[2px] bottom-0 bg-[#EAE9E9]">
                              {isActive ? (
                                <div className="absolute inset-0 w-full bg-[#094358] animate-line"></div>
                              ) : null}
                            </div>
                          </div>
                        );
                      })
                    : null}

                  <div className="md:hidden mt-8">
                    <AccordionImage activeItem={activeItem} />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Right */}
          <div className="hidden md:block">
            <AccordionImage activeItem={activeItem} />
          </div>
        </div>
      </HeightFixer>
    </section>
  );
}

function AccordionImage({ activeItem }) {
  return activeItem ? (
    <div className="rounded-[30px] bg-[#efece8] w-full md:max-w-[588px] overflow-hidden aspect-[588/660]">
      <AnimatePresence exitBeforeEnter={true}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            type: 'tween',
            ease: 'easeInOut',
          }}
          key={`image-${activeItem?.image?.id}`}
          className="h-full w-full"
        >
          <Image image={activeItem.image} className="h-full w-full object-cover" />
        </motion.div>
      </AnimatePresence>
    </div>
  ) : null;
}
