import React, { useState, useRef, useEffect } from 'react';
import { SimpleMedia } from './media/SimpleMedia';
import { Tab } from '@headlessui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';

export const SwitchTabs = ({ leftColumn, rightColumn, switchColour, singleGroup }) => {
  const activeSwitch = [
    'transition-all duration-500 rounded-[60px] border-white border-[1.5px] px-2 md:px-[40px] py-4 t-14 md:text-base',
  ];
  const inActiveSwitch = [
    'transition-all duration-500 rounded-[60px] opacity-50 border-style-hidden border-[1.5px] px-2 md:px-[40px] py-4 t-14 md:text-base',
  ];
  return (
    <div className="container">
      <Tab.Group>
        {/* if Single Group hide tab controls*/}
        <div
          className={`${singleGroup ? 'hidden' : 'flex'} justify-center sticky z-10 top-20 mb-12 md:mb-16 lg:mb-[70px]`}
        >
          <Tab.List className={`inline-flex rounded-[60px] bg-${switchColour}`}>
            <Tab className={({ selected }) => (selected ? `${activeSwitch}` : `${inActiveSwitch}`)}>
              {leftColumn.label}
            </Tab>
            <Tab className={({ selected }) => (selected ? `${activeSwitch}` : `${inActiveSwitch}`)}>
              {rightColumn.label}
            </Tab>
          </Tab.List>
        </div>
        <Tab.Panels>
          <Tab.Panel className={'space-y-8 md:space-y-[50px]'}>
            <TabContent group={leftColumn} />
          </Tab.Panel>
          {/* If Single Group hide unused tab content */}
          {singleGroup ? (
            ''
          ) : (
            <Tab.Panel className={'space-y-8 md:space-y-[50px]'}>
              <TabContent group={rightColumn} />
            </Tab.Panel>
          )}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export const TabContent = ({ group }) => {
  const refs = [];
  const [active, set_active] = useState(0);

  const [swiper, set_swiper] = useState({});

  useEffect(() => {
    if (swiper.on) {
      swiper.on('slideChange', function () {
        set_active(swiper.activeIndex);
      });
    }
  }, [swiper]);

  useEffect(() => {
    if (swiper.slideTo && swiper.activeIndex !== active) {
      swiper.slideTo(active);
    }
  }, [swiper, active]);

  // Scroll handler
  useEffect(() => {
    refs.reverse();
    const onScroll = () => {
      if (typeof window === undefined) return;

      const scrollY = document.documentElement.scrollTop;
      let matchFound = false
      for (let i in refs) {
        const ref = refs[i];
        if (!ref.current) break;
        const top = window.pageYOffset + ref.current.getBoundingClientRect().top - 400;
        if (scrollY > top) {
          set_active(refs.length - i);
          matchFound = true;
          break;
        }
      }

      if (!matchFound) {
        set_active(1);
      }
    };

    window.addEventListener('scroll', onScroll);
    onScroll();
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const imageContClasses = [];

  if (group.mediaBackgroundColour === 'lightGreen') {
    imageContClasses.push('bg-lightGreen safari-overflow contain-paint');
  } else if (group.mediaBackgroundColour === 'coral') {
    imageContClasses.push('bg-coral safari-overflow contain-paint');
  } else if (group.mediaBackgroundColour === 'fadedGreen') {
    imageContClasses.push('bg-fadedGreen safari-overflow contain-paint');
  } else if (group.mediaBackgroundColour === 'fadedMaroon') {
    imageContClasses.push('bg-fadedMaroon safari-overflow contain-paint');
  }

  // console.log(ev);

  return (
    <div className="container mx-auto grid grid-cols-12 items-start gap-y-8 md:gap-y-0 md:gap-x-12 lg:gap-x-[95px]">
      <div className={`col-span-12 md:col-span-6 h-full hidden md:block`}>
        <div className="sticky fadein-top-controls">
          <div className={`rounded-[12px] ${imageContClasses}`}>
            <div className="sticky mx-auto sm:w-3/4 md:w-full">
              <Swiper
                onInit={(ev) => {
                  set_swiper(ev);
                }}
                modules={[EffectFade]}
                effect="fade"
                fadeEffect={{ crossFade: true }}
                spaceBetween={20}
                itemsperview={1}
                speed={500}
                loop={true}
              >
                {group.items ? group.items.map((item, i) => (
                  <SwiperSlide key={i}>
                    <SimpleMedia simpleMedia={item.mediaItem.simpleMedia} />
                  </SwiperSlide>
                )):null}
              </Swiper>
            </div>
          </div>
        </div>
      </div>

      <div className={`col-span-12 md:col-span-6 text-center md:text-left`}>
        {group.items ?group.items.map((item, i) => {
          const ref = useRef();
          refs.push(ref);
          return (
            <div key={i} className="md:py-32 lg:py-[150px] xl:pt-[200px] xl:pb-[250px] mb-12 md:mb-0">
              <div className="relative">
                <div ref={ref} className="absolute top-[-80%]"></div>
                <div className={`block md:hidden rounded-[12px] ${imageContClasses} mb-6 max-w-[400px] mx-auto`}>
                  <div className="mx-auto">
                    <SimpleMedia simpleMedia={item.mediaItem.simpleMedia} />
                  </div>
                </div>
                <h6 className="mb-5 font-medium t-18 font-body text-coral">{item.subheading}</h6>
                <h3 className="text-h3 mb-2 max-w-[380px] mx-auto md:ml-0">{item.heading}</h3>
                <p className="t-18 max-w-[375px] mx-auto md:ml-0" dangerouslySetInnerHTML={{ __html: item.content }} />
              </div>
            </div>
          );
        }):null}
      </div>
    </div>
  );
};
