import React, { useState } from 'react';

export const useDate = (val) => {
  const [date, setDateValue] = useState(getDate(val))

  function getDate(date) {
    //Parse a date with UNIX format
    return new Date(parseInt(date) * 1000)
  }
  function setDate(date) {
    // @ts-ignore
    setDateValue(getDate(date))
  }

  return [date, setDate]
}