import React from 'react';
import { TextContent } from '../TextContent';
import Image from '~/components/elements/Image';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { Link } from 'gatsby';

export const BlockQuoteCardLight = (props) => {
  const caseStudyItem = props?.caseStudyItem[0]
  const summary = caseStudyItem?.postTypeCaseStudy?.caseStudySummary

  // console.log(caseStudyItem.uri)

  const animationValue = [];
  if(props?.alignment === 'left'){
    animationValue.push('30')
  } else {
    animationValue.push('-30')
  }

  return (
    <blockquote className="section-lrg">
      <InViewAnimator className="bg-white rounded-4xl flex flex-col md:flex-row lg:gap-6 items-stretch overflow-hidden"
        inner={(controls) => (
          <>
            <div
              className={`w-full min-h-[300px] 
              md:w-1/2 md:min-w-[50%] 
              xl:w-[485px] xl:min-w-[485px] relative ${ (props?.alignment === 'left') ? 'order-1': 'order-1 md:order-2' }`}>
              <Image className="absolute inset-0 md:h-full" objectFit="cover" objectPosition="center" image={caseStudyItem?.featuredImage?.node}/>
              <InViewAnimatorBasicItem className="absolute top-6 left-6 bg-white w-20 h-20 border-[1px] border-black/10 rounded-xl lg:w-28 lg:h-28 flex items-center justify-center"
                controls={controls}
                delay={.2}
                duration={.5}
                prop='scale'
                start={0}
                end={1}>
                <div className="w-[60px]">
                  <Image className="w-full" image={summary.logo}/>
                </div>
              </InViewAnimatorBasicItem>
            </div>
            <InViewAnimatorBasicItem className={`p-8 xl:py-28 xl:px-24 flex-auto h-full self-center text-center md:text-left ${ (props?.alignment === 'left') ? 'order-2': 'order-1 md:order-1' }`}
              controls={controls}
              delay={.6}
              duration={.5}
              prop='x'
              start={animationValue}>
              {summary?.subheading &&
                <h2 className="t-40 pb-4 lg:pb-8 relative
                after:absolute after:inset-x-0 after:bottom-0 after:h-[1px] after:bg-[#1d2222]/20">
                  {summary?.subheading}
                </h2>
              }
              <span className="block mt-8 mb-4 t-18 font-body max-w-[600px]" dangerouslySetInnerHTML={{__html: summary?.quote}}/>
              <cite className="not-italic font-bold t-18-tag font-body">
                {summary.name && summary.name + ', '}{summary.company && summary.company }
              </cite>
              {/* <Link
                to={caseStudyItem?.uri || '#'}
                className={` flex whitespace-nowrap justify-center md:justify-start font-body font-bold text-18 text-coral underline mt-1 hover:opacity-60 transition-all duration-200`}
              >
                <span>Read story</span>
              </Link> */}
            </InViewAnimatorBasicItem>
          </>
        )}
      />
    </blockquote>
  )
}