import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useCareerListings } from '~/hooks/useCareerListings';
import { Arrow } from '../elements/Icon';

export const CareerListings = ({ noRoles }) => {
  const [roles, setRoles] = React.useState([]);
  const { getGreenhouseJobs, getGraphJobs } = useCareerListings();

  React.useEffect(() => {
    getGreenhouseJobs().then((res) => {
      setRoles(res.jobs);
    });
  }, []);

  if (roles?.length > 0) {
    noRoles = false;
  }

  const rolesOrganizedByDepartment = roles.reduce((prev, curr) => {
    const department = curr?.departments?.[0]?.name;

    if (!department) {
      return prev;
    }

    const prevRoles = prev[department] || [];

    return {
      ...prev,
      [department]: [...prevRoles, curr],
    };
  }, {});

  const departmentNames = Object.keys(rolesOrganizedByDepartment);

  return (
    <div className="max-w-[1092px] px-6 mx-auto space-y-[15px]" id="career-listings">
      {noRoles ? (
        <div className="rounded-[20px] border border-borderGrey bg-white text-darkGreen text-center py-6 md:py-[28px] px-6">
          <p className="t-18 opacity-60">No roles currently open</p>
        </div>
      ) : (
        <div className="career-listings__roles flex flex-col gap-8">
          {departmentNames?.length > 0 &&
            departmentNames.map((department, i) => {
              return (
                <div className="roles__department" key={`department-${i}`}>
                  {/* Department heading */}
                  <h3 className="t-25 mb-4">{department}</h3>

                  {/* Department Roles */}
                  <div className="flex flex-col gap-2">
                    {rolesOrganizedByDepartment[department]?.map((role, i) => (
                      <Link
                        key={`role-${i}`}
                        className="rounded-[20px] grid grid-cols-1 sm:grid-cols-3 gap-y-4 sm:gap-x-4 justify-between border border-borderGrey bg-white text-darkGreen py-6 md:py-[28px] px-6 md:px-[36px] group hover:md:scale-[1.025] transition-all duration-400"
                        to={role?.absolute_url || '#'}
                      >
                        {/* Title */}
                        <p className="t-18">{role?.title}</p>
                        {/* Location */}
                        <p className="t-18 opacity-60">{role?.location?.name}</p>
                        {/* Arrow  */}
                        <div className="rounded-full border border-grey w-[28px] h-[28px] flex items-center justify-center p-[4px] sm:ml-auto group-hover:bg-coral transition-all duration-400 relative overflow-hidden">
                          <span className="absolute inset-0 translate-x-[-28px] group-hover:translate-x-[0] w-[17px] h-[12px] m-auto transition-all duration-800 ">
                            <Arrow active={true} />
                          </span>
                          <span className="absolute inset-0 group-hover:translate-x-[28px]  w-[17px] h-[12px] m-auto transition-all duration-800 ">
                            <Arrow active={false} />
                          </span>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              );
            })}
        </div>
      )}

      <div className="text-center text-darkGreen t-18 font-medium !mt-8 md:!mt-[63px]">
        <p>
          Can’t see what you’re looking for?{' '}
          <Link to={'/contact/' || '#'} className={`text-coral underline hover:opacity transition-all duration-200`}>
            <span>Contact us.</span>
          </Link>
        </p>
      </div>
    </div>
  );
};
