import React from 'react';
import { TextContent } from './TextContent';
import { SimpleMedia } from './media/SimpleMedia';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const ImageAndText = ({
  mediaItems,
  columnOne,
  columnTwo,
}) => {

  // console.log(columnOne)
  return(
    <InViewAnimator
      inner={(controls) => (
        <>
        <div className='overflow-hidden'>
          <InViewAnimatorBasicItem className='container' 
           controls={controls}
            delay={.2}
            duration={.8} 
            prop='y'
            start={50}>
            <SimpleMedia simpleMedia={mediaItems.simpleMedia} />
          </InViewAnimatorBasicItem>
          <hr className='opacity-20 border-t-[1px] mt-[-1px]' />
        </div>
        <div className='mx-auto max-w-[980px] grid grid-cols-1 md:grid-cols-2 gap-y-8 md:gap-x-0 pt-8 md:pt-16 lg:pt-[70px]'>
            <InViewAnimatorBasicItem 
             controls={controls}
              delay={.6}
              duration={1} >
              <TextColumn content={columnOne}/>
            </InViewAnimatorBasicItem>
            <InViewAnimatorBasicItem 
             controls={controls}
              delay={.8}
              duration={1}>
              <TextColumn content={columnTwo}/>
            </InViewAnimatorBasicItem>
        </div>
        </>
      )}
    />
  );
};

export const TextColumn = ({
  content
}) => {

  return(
    <TextContent {...content} />
  );
};