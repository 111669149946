import React from 'react';
import Image from '~/components/elements/Image';
import { Link } from 'gatsby';
import { ButtonArrow } from '~/components/elements/buttons/Button';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { link } from 'fs';

export const IconTextCards = ({
  layout = 'default',
  iconCards,
  cardBorderStyle,
  parentBackground,
  iconSize,
  cardBackground,
  textStyle,
  linkStyle,
}) => {
  if (layout === 'image-bottom') {
    return <IconTextCardsImageBottom iconCards={iconCards} />;
  }

  const parentClasses = [];
  let cardClasses = [];
  const iconClasses = [];
  let cardHeadingClasses = [];
  let cardContentClasses = [];

  // Controlling Parent backgrounds
  if (parentBackground === 'white') {
    parentClasses.push(
      `rounded-3xl bg-white py-6 md:pt-[46px] md:pb-[40px] text-darkGreen md:mx-6 xl:mx-[50px] gap-6 md:gap-0`
    );
  }

  // Controlling amount of cards in row
  if (iconCards.length === 6) {
    parentClasses.push(`md:grid-cols-3 gap-4`);
  } else if (iconCards.length === 4) {
    parentClasses.push(`md:grid-cols-2 gap-4`);
  } else if (iconCards.length === 3) {
    parentClasses.push(`md:grid-cols-3 lg:gap-x-[24px]`);
  } else if (iconCards.length === 2) {
    parentClasses.push(`md:grid-cols-2`);
  } else {
    parentClasses.push(`md:grid-cols-1`);
  }

  // Controlling border style
  if (cardBorderStyle === 'small') {
    parentClasses.push(`card-border-small`);
  } else if (cardBorderStyle === 'full') {
    parentClasses.push(`gap-6 xl:gap-8`);
    cardClasses.push(`border border-white/[.25] rounded-2xl pt-10 pb-8`);
  } else {
    parentClasses.push(`md:gap-x-4`);
  }

  // Controlling icon size
  if (iconSize === 'small') {
    iconClasses.push(`w-20 h-24`);
  } else if (iconSize === 'tiny') {
    iconClasses.push(`w-[36px] h-[36px] md:w-[48px] md:h-[48px]`);
  } else if (iconSize === 'large') {
    iconClasses.push(`w-[172px] h-[124px]`);
  }

  // Card background Colour
  if (cardBackground === 'white') {
    cardClasses.push(`bg-white rounded-2xl py-8 md:py-16`);
    cardContentClasses.push(`max-w-[380px]`);
  }

  // Card background Colour
  if (cardBackground === 'light-taupe') {
    parentClasses.push(' mx-auto max-w-[1200px]');
    cardClasses.push(`bg-lightTaupe rounded-2xl !py-[50px] !px-[16px]`);
    cardContentClasses.push(`!max-w-[346px] !t-18 text-[#515E60]`);
    cardHeadingClasses.push('!font-body t-22 font-medium text-[#071B1E]');
  }

  //Controlling text style
  if (textStyle === 'large') {
    cardHeadingClasses.push(`t-35`);
    cardContentClasses.push(`t-18 max-w-[395px]`);
  } else if (textStyle === 'mid') {
    cardHeadingClasses.push(`text-h4`);
    cardContentClasses.push(`t-18 max-w-[265px]`);
  } else {
    cardHeadingClasses.push(`text-h4`);
    cardContentClasses.push(`t-16 max-w-[270px]`);
  }

  if (layout === 'careers') {
    cardClasses = ['bg-white rounded-[30px] py-[40px] px-[32px]'];
    cardHeadingClasses = ['t-24 font-medium font-body'];
    cardContentClasses = ['t-17 font-body text-dark70'];
  }

  return (
    <InViewAnimator
      className={`container`}
      inner={(controls) => (
        <div className={`icon-text-container grid gap-y-6 grid-cols-1 ${parentClasses.join(' ')}`}>
          {iconCards.map((card, i) => (
            <IconTextCard
              key={i}
              linkStyle={linkStyle}
              staggerDelay={i}
              card={card}
              controls={controls}
              cardClasses={cardClasses.join(' ')}
              cardHeadingClasses={cardHeadingClasses.join(' ')}
              cardContentClasses={cardContentClasses.join(' ')}
              iconClasses={iconClasses.join(' ')}
            />
          ))}
        </div>
      )}
    />
  );
};

const IconTextCard = ({
  card,
  cardClasses,
  iconClasses,
  cardHeadingClasses,
  cardContentClasses,
  staggerDelay,
  controls,
  linkStyle,
}) => {
  const link = card.link;

  if (card?.link) {
    return (
      <InViewAnimatorBasicItem
        className={`icon-text-card relative lg:px-6 ${cardClasses}`}
        controls={controls}
        delay={staggerDelay / 3}
        duration={1}
      >
        {/* Link Start */}
        <Link
          to={link?.url}
          className={`icon-text-card-link flex flex-col space-y-4 lg:space-y-8 group transition-all duration-200 h-full`}
          target={link?.target}
        >
          {card?.icon && (
            <div className={`mx-auto`}>
              <div className={`${iconClasses}`}>
                <Image image={card.icon} className={`w-full h-full object-contain object-center`} />
              </div>
            </div>
          )}
          <div className="text-center space-y-3 px-4 h-auto flex flex-col flex-auto">
            <h4 className={`${cardHeadingClasses} mb-3`}>{card.heading}</h4>
            <p className={`mx-auto mb-3 ${cardContentClasses}`} dangerouslySetInnerHTML={{ __html: card.content }} />
            <div className={`pt-1 md:pt-4 !mb-2 md:!mb-4 !mt-auto`}>
              {linkStyle === 'underline' ? (
                <span className="underline-span text-coral font-medium block mx-auto">{link?.title}</span>
              ) : (
                <span className={`flex items-center space-x-[9px] whitespace-nowrap t-16 justify-center`}>
                  <span>{link?.title}</span>
                  <span className="inline-block w-[24px] h-[24px] group-hover:translate-x-4 transition-all duration-200">
                    <ButtonArrow arrowFill={true} />
                  </span>
                </span>
              )}
            </div>
          </div>
        </Link>
      </InViewAnimatorBasicItem>
    );
  } else {
    return (
      <InViewAnimatorBasicItem
        className={`icon-text-card relative space-y-4 lg:space-y-8 lg:px-6 flex flex-col flex-auto ${cardClasses}`}
        controls={controls}
        delay={staggerDelay / 3}
        duration={1}
      >
        {card?.icon && (
          <div className={`${iconClasses} mx-auto`}>
            <Image image={card.icon} className={`w-full h-full object-contain object-center`} />
          </div>
        )}
        <div className="text-center px-4 h-auto flex flex-col flex-auto">
          <h4 className={`${cardHeadingClasses} mb-3`}>{card.heading}</h4>
          <p className={`mx-auto ${cardContentClasses}`} dangerouslySetInnerHTML={{ __html: card.content }} />
        </div>
      </InViewAnimatorBasicItem>
    );
  }
};

function IconTextCardsImageBottom({ iconCards }) {
  return (
    <div className="grid md:grid-cols-2 gap-6 container !max-w-[1280px]">
      {iconCards.map((card, i) => (
        <div
          key={`ic-${i}`}
          className="rounded-[30px] bg-taupe px-8 pt-12 md:px-[70px] md:pt-[64px] text-center flex flex-col "
        >
          <div className="max-w-[460px] w-full mx-auto flex-auto flex flex-col">
            <div className="mb-8 md:mb-12 px-2">
              <div className="t-26 mb-2" dangerouslySetInnerHTML={{ __html: card.heading }} />
              <div
                className="t-18"
                style={{ textWrap: 'balance' }}
                dangerouslySetInnerHTML={{ __html: card.content }}
              />
            </div>
            <div className="w-full flex-auto flex flex-col justify-end">
              <Image image={card.icon} className="w-full !h-auto object-contain object-center" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
