import { motion } from 'framer-motion';
import React from 'react';
import { useWindowSize } from '../../hooks/useWindowSize';
import ButtonGroup from '../elements/buttons/ButtonGroup';

export const TextContent = ({
  visualControls,
  centered = true,
  innerOnly = false,
  subheading,
  heading,
  headingSize,
  contentSize,
  subheadingStyle,
  isMasthead,
  content,
  link,
  smallMargin,
  linkStyle,
  animatedHeading = false,

  isUseCase = false,
  isAdFormat = false,
  links,
  headingType,
  extraContentSpacing = false,
  visibleFields = ['heading', 'subheading', 'content', 'link'],
  className = '',
}) => {
  if (!visualControls) visualControls = { restrictWidth: {} };
  if (link && !link.type) link = { link };

  const classes = {
    outerContainer: ['text-content', className],
    container: ['text-center '],
    subheading: [`${isUseCase ? 'text-white' : 'text-lightBlue'} mb-5`],
    heading: ['pt-1'],
    content: ['text-content__content', `pt-2`],
    links: [],
  };

  if (!innerOnly) {
    classes.outerContainer.push('container');
  }

  if (centered) {
    classes.container.push('mx-auto');
    classes.links.push('flex justify-center');

    if (!visualControls.restrictWidth?.main) {
      classes.container.push('max-w-[900px]');
    }
    if (visualControls.restrictWidth?.content) {
      classes.content.push('mx-auto');
    }
    if (visualControls.restrictWidth?.heading) {
      classes.heading.push('mx-auto text-center');
    }
  } else {
    classes.container.push('md:text-left');
    if (visualControls.restrictWidth?.main) {
      classes.container.push('mx-auto md:mx-0');
    }
    if (visualControls.restrictWidth?.content) {
      classes.content.push('mx-auto md:mx-0');
    }
    if (visualControls.restrictWidth?.heading) {
      classes.heading.push('mx-auto md:mx-0');
    }
  }

  if (headingSize === '62') {
    classes.heading.push('t-62');
    classes.content.push('mt-3');
    classes.subheading.push('md:pb-2');
  } else if (headingSize === '86') {
    classes.heading.push('text-h1');
  } else if (headingSize === '106') {
    classes.heading.push('t-106');
  } else if (headingSize === 'h2') {
    classes.heading.push('text-h2 mb-2 md:mb-3');
    classes.links.push('!mt-[22px]');
  } else if (headingSize === '40') {
    classes.heading.push('t-40');
  } else if (headingSize === '38') {
    classes.heading.push('t-38 leading-[1.2]');
  } else if (headingSize === '25') {
    classes.heading.push('t-25');
    classes.links.push('!mt-[16px]');
  } else {
    classes.heading.push('t-70');
  }

  if (contentSize === '20') {
    classes.content.push('t-20');
  } else if (contentSize === '19') {
    classes.content.push('t-19');
  } else if (contentSize === '18') {
    classes.content.push('t-18');
  } else if (contentSize === '16') {
    classes.content.push('t-16');
  }

  if (subheadingStyle === 'plain') {
    classes.subheading.push('is-plain');
  }

  if (subheading === 'Flexible debt capital') {
    classes.subheading.push('!text-[#071B1EB2] !font-body');
  }

  if (visualControls?.subheadingSize === 'large') {
    classes.subheading.push('t-subheading-large');
  } else {
    classes.subheading.push('t-subheading');
  }

  if (visualControls?.subheadingColor) {
    if (visualControls?.subheadingColor === 'darkNavy') {
      classes.subheading.push('text-darkNavy');
    } else if (visualControls?.subheadingColor === 'lightBlue') {
      classes.subheading.push('text-lightBlue');
    } else {
      classes.subheading.push('text-white');
    }
  }

  let HeadingTag = 'h3';
  let SubheadingTag = 'h4';
  if (isMasthead) {
    HeadingTag = 'h1';
    SubheadingTag = 'h2';
  } else if (headingSize === 'default') {
    HeadingTag = 'h2';
    SubheadingTag = 'h3';
  }

  if (extraContentSpacing) {
    classes.content.push('md:!mt-[25px]');
  }

  if (visualControls?.fadedText) {
    classes.content.push('opacity-90');
  }

  return (
    <TextContentWrapper innerOnly={innerOnly} className={classes.outerContainer.join(' ')}>
      <div className={classes.container.join(' ')} {...maxWidthProps(visualControls.restrictWidth?.main)}>
        {subheading && visibleFields.includes('subheading') && (
          <HeadingTag
            className={`${centered ? 'mx-auto' : ''} ${classes.subheading.join(' ')}`}
            {...maxWidthProps(visualControls.restrictWidth?.subheading)}
            dangerouslySetInnerHTML={{
              __html: subheading,
            }}
          />
        )}

        {heading && visibleFields.includes('heading') && (
          <HeadingTag
            className={`${centered ? 'mx-auto' : ''} ${classes.heading.join(' ')}`}
            {...maxWidthProps(visualControls.restrictWidth?.heading)}
            dangerouslySetInnerHTML={{
              __html: heading,
            }}
          />
        )}
        {content && visibleFields.includes('content') && (
          <div
            {...maxWidthProps(visualControls.restrictWidth?.content)}
            className={classes.content.join(' ')}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}

        {links && visibleFields.includes('link') && (
          <div className={`mt-10  ${classes.links.join(' ')}`}>
            <ButtonGroup links={links} />
          </div>
        )}
      </div>
    </TextContentWrapper>
  );
};

export const maxWidthProps = (setting) => {
  if (!setting) return {};

  const { width } = useWindowSize();
  return {
    style: {
      maxWidth: width > 768 ? `${setting}px` : null,
    },
  };
};

export const TextContentWrapper = ({ innerOnly, className, children, style = null }) =>
  innerOnly ? (
    children
  ) : (
    <div className={className} style={style}>
      {children}
    </div>
  );
