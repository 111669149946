import React from "react";
import Image from "~/components/elements/Image";
import { CheckLrg } from '../../elements/Icon';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const CustomerCard = ({
  card,
  delayProp = 1
 }) => {

  const imageContClasses = [];
  
  if(card.backgroundColour === 'lightGreen'){
    imageContClasses.push('bg-lightGreen');
  } else if (card.backgroundColour === 'lightCoral'){
    imageContClasses.push('bg-lightCoral');
  } else if (card.backgroundColour === 'blue'){
    imageContClasses.push('bg-blue');
  } else if (card.backgroundColour === 'coral'){
    imageContClasses.push('bg-Coral');
  }

  return(
    <InViewAnimator className="h-[160px] flex items-center customer-card"
      inner={(controls) => (
        <div className="relative shadow-lg rounded-lg bg-white text-darkGreen flex items-center p-[1.8rem] mr-[35px] w-[370px]">
          <div className="absolute top-0 right-0 translate-x-4 -translate-y-4">
           <InViewAnimatorBasicItem
              controls={controls}
              delay={delayProp/4}
              duration={.5}
              prop='scale'
              start={0}
              end={1}>
            <CheckLrg />
            </InViewAnimatorBasicItem>
          </div>
          <div className={`w-[70px] h-[70px] rounded-full overflow-hidden mr-6 ${imageContClasses} safari-overflow`}>
            <Image image={card.image} className={`w-full h-full object-contain object-center`} />
          </div>
          <div className="space-y-1">
            <h4 className="p-large font-body font-medium text-xs !tracking-wide">{card.name}</h4>
            <p className="text-coral text-sm">{card.message}</p>
            <p className="opacity-60 text-sm">{card.role}</p>
          </div>
        </div>
      )}
    />
  );
};