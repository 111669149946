import React from 'react';
import { Button } from '~/components/elements/buttons/Button';
import Image from '~/components/elements/Image';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const FourOhFour = (page) => {
  return (
    <InViewAnimator className="container"
      inner={(controls) => (
        <>
          <div className="lg:flex gap-8 pt-20 lg:pt-40">
            <InViewAnimatorBasicItem className="xl:ml-40 lg:min-w-[486px] mt-8 lg:mt-20 text-center lg:text-left"
             controls={controls}
             delay={.2}
             duration={1.2}>
              <h1 className="t-192 leading-[1]">{page?.fourOhFour?.heading}</h1>
              <h2 className="t-40 mt-2 lg:mt-0 mb-8">{page?.fourOhFour?.subheading}</h2>
              <div className="max-w-[250px] mx-auto lg:ml-0">
                <Button type="button" color="coral"
                        link={page?.fourOhFour?.ctaButton}>
                </Button>
              </div>
            </InViewAnimatorBasicItem>
            <InViewAnimatorBasicItem className="lg:min-w-[854px] min-h-[400px] lg:min-h-[668px] relative"
             controls={controls}
             delay={.4}
             duration={1.2}
             prop={'x'}
             start={30}>
              <Image className="absolute w-full h-full top-5 right-4 xl:right-[-150px]" image={page?.fourOhFour?.image}/>
              
            </InViewAnimatorBasicItem>
          </div>
        </>
      )}
    />
  )
}