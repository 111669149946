import React from 'react';
// Text
import { TextContent } from '~/components/flexible/TextContent';
// Masthead
import HomeMasthead from '~/components/flexible/mastheads/HomeMasthead';
import TextMasthead from '~/components/flexible/mastheads/TextMasthead';
import { TwoColumnMasthead } from '~/components/flexible/mastheads/TwoColumnMasthead';
import { LegalMasthead } from './flexible/mastheads/LegalMasthead';
import { ContainedMasthead } from '~/components/flexible/mastheads/ContainedMasthead';
import { TransitionMasthead } from '~/components/flexible/mastheads/TransitionMasthead';
import { CustomerStoriesMasthead } from './flexible/mastheads/CustomerStoriesMasthead';
// Marquee
import { LogoBar } from '~/components/flexible/iconLogos/LogoBar';
import { FlexMarquee } from '~/components/flexible/marqueeCards/FlexMarquee';
// Icon Grid
import { IconTextCards } from '~/components/flexible/iconLogos/IconTextCards';
import { LinkCards } from '~/components/flexible/iconLogos/LinkCards';
import { IconGrid } from '~/components/flexible/iconLogos/IconGrid';
// Two Column
import { TwoColumnVerticalScroll } from '~/components/flexible/twoColumn/TwoColumnVerticalScroll';
import { TwoColumnSimpleMedia } from '~/components/flexible/twoColumn/TwoColumnSimpleMedia';
import { TwoColumnGridSwiper } from '~/components/flexible/twoColumn/TwoColumnGridSwiper';
import { TwoColumnStickyCard } from '~/components/flexible/twoColumn/TwoColumnStickyCard';
import { TwoColumnCards } from '~/components/flexible/twoColumn/TwoColumnCards';
import { TwoColumnFullBleed } from '~/components/flexible/twoColumn/TwoColumnFullBleed';
import { TwoColumnMediaCollection } from '~/components/flexible/twoColumn/TwoColumnMediaCollection';
// Media
import { MediaCollection } from '~/components/flexible/media/MediaCollection';
import { SimpleMedia } from '~/components/flexible/media/SimpleMedia';
// Post Object
import { CaseStudyPreview } from '~/components/flexible/CaseStudyPreview';
// Swiper
import { Accordion } from '~/components/flexible/swipers/Accordion';
import { CaseStudySlider } from './flexible/swipers/CaseStudySlider';
//Misc
import { Stats } from '~/components/flexible/Stats';
import { LargeStats } from '~/components/flexible/LargeStats';
import { Faqs } from '~/components/flexible/Faqs';
import { SwitchTabs } from '~/components/flexible/SwitchTabs';
import { InternalNavigation } from '~/components/flexible/InternalNavigation';
import { ImageAndText } from '~/components/flexible/ImageAndText';
import { GridCards } from '~/components/flexible/twoColumn/GridCards';
import { TeamList } from '~/components/flexible/TeamList';
import { BlockQuoteCardLight } from '~/components/flexible/BlockQuoteCardLight';
import { BlockQuoteCardDark } from '~/components/flexible/BlockQuoteCardDark';
import { CaseStudyListing } from '~/components/flexible/CaseStudyListing';
import { CareerListings } from '~/components/flexible/CareerListings';
import { CaseStudyLogoBlock } from '~/components/flexible/CaseStudyLogoBlock';
// Contact
import { ContactGroup } from './flexible/contact/ContactGroup';

// Resources
import { CategoryList } from '~/components/resources/CategoryLists';

//404
import { FourOhFour } from '~/components/flexible/FourOhFour';
import { CaseStudyMasthead } from './flexible/mastheads/CaseStudyMasthead';
import { FunnelDiagram } from '~/components/flexible/FunnelDiagram';
import { IconTextGrid } from '~/components/flexible/IconTextGrid';
import { LatestNews } from '~/components/flexible/LatestNews';
import { TabAccordion } from '~/components/flexible/TabAccordion';
import { Divider } from '~/components/flexible/Divider';
import { Principles } from '~/components/flexible/Principles';
import HtmlEmbed from '~/components/flexible/HtmlEmbed';

export const ContentRenderer = ({ prefix, content = [] }) => {
  return (
    <>
      {content.map((layout, i) => {
        const layoutName = layout.fieldGroupName.replace(prefix, '');
        if (layoutName === 'TextContent') {
          return <TextContent key={layoutName + i} {...layout} />;
        } else if (layoutName === 'HomeMasthead') {
          return <HomeMasthead key={layoutName + i} {...layout} />;
        } else if (layoutName === 'LogoBar') {
          return <LogoBar key={layoutName + i} {...layout} />;
        } else if (layoutName === 'IconAndTextCards') {
          return <IconTextCards key={layoutName + i} {...layout} />;
        } else if (layoutName === 'FlexMarquee') {
          return <FlexMarquee key={layoutName + i} {...layout} />;
        } else if (layoutName === 'CaseStudy') {
          return <CaseStudyPreview key={layoutName + i} {...layout} />;
        } else if (layoutName === 'CaseStudyLogoBlock') {
          return <CaseStudyLogoBlock key={layoutName + i} {...layout} />;
        } else if (layoutName === 'TwoColumnVerticalScroll') {
          return <TwoColumnVerticalScroll key={layoutName + i} {...layout} />;
        } else if (layoutName === 'MediaCollection') {
          return <MediaCollection key={layoutName + i} {...layout} />;
        } else if (layoutName === 'SimpleMedia') {
          return <SimpleMedia key={layoutName + i} {...layout} />;
        } else if (layoutName === 'TextMasthead') {
          return <TextMasthead key={layoutName + i} {...layout} />;
        } else if (layoutName === 'TwoColumnSimpleMedia') {
          return <TwoColumnSimpleMedia key={layoutName + i} {...layout} />;
        } else if (layoutName === 'Accordion') {
          return <Accordion key={layoutName + i} {...layout} />;
        } else if (layoutName === 'LinkCards') {
          return <LinkCards key={layoutName + i} {...layout} />;
        } else if (layoutName === 'TwoColumnGridSwiper') {
          return <TwoColumnGridSwiper key={layoutName + i} {...layout} />;
        } else if (layoutName === 'Stats') {
          return <Stats key={layoutName + i} {...layout} />;
        } else if (layoutName === 'LargeStats') {
          return <LargeStats key={layoutName + i} {...layout} />;
        } else if (layoutName === 'Faqs') {
          return <Faqs key={layoutName + i} {...layout} />;
        } else if (layoutName === 'TwoColumnMasthead') {
          return <TwoColumnMasthead key={layoutName + i} {...layout} />;
        } else if (layoutName === 'ContainedMasthead') {
          return <ContainedMasthead key={layoutName + i} {...layout} />;
        } else if (layoutName === 'SwitchTabs') {
          return <SwitchTabs key={layoutName + i} {...layout} />;
        } else if (layoutName === 'LegalMasthead') {
          return <LegalMasthead key={layoutName + i} {...layout} />;
        } else if (layoutName === 'fourOhFour') {
          return <FourOhFour key={layoutName + i} {...layout} />;
        } else if (layoutName === 'InternalNavigation') {
          return <InternalNavigation key={layoutName + i} {...layout} />;
        } else if (layoutName === 'CaseStudyMasthead') {
          return <CaseStudyMasthead key={layoutName + i} {...layout} />;
        } else if (layoutName === 'TwoColumnStickyCard') {
          return <TwoColumnStickyCard key={layoutName + i} {...layout} />;
        } else if (layoutName === 'TwoColumnCards') {
          return <TwoColumnCards key={layoutName + i} {...layout} />;
        } else if (layoutName === 'TwoColumnFullBleed') {
          return <TwoColumnFullBleed key={layoutName + i} {...layout} />;
        } else if (layoutName === 'TwoColumnMediaCollection') {
          return <TwoColumnMediaCollection key={layoutName + i} {...layout} />;
        } else if (layoutName === 'ImageAndText') {
          return <ImageAndText key={layoutName + i} {...layout} />;
        } else if (layoutName === 'TransitionMasthead') {
          return <TransitionMasthead key={layoutName + i} {...layout} />;
        } else if (layoutName === 'CustomerStoryMasthead') {
          return <CustomerStoriesMasthead key={layoutName + i} {...layout} />;
        } else if (layoutName === 'GridCards') {
          return <GridCards key={layoutName + i} {...layout} />;
        } else if (layoutName === 'BlockQuoteCardLight') {
          return <BlockQuoteCardLight key={layoutName + i} {...layout} />;
        } else if (layoutName === 'BlockQuoteCardDark') {
          return <BlockQuoteCardDark key={layoutName + i} {...layout} />;
        } else if (layoutName === 'TeamList') {
          return <TeamList key={layoutName + i} {...layout} />;
        } else if (layoutName === 'CaseStudyListing') {
          return <CaseStudyListing key={layoutName + i} {...layout} />;
        } else if (layoutName === 'ContactGroup') {
          return <ContactGroup key={layoutName + i} {...layout} />;
        } else if (layoutName === 'IconGrid') {
          return <IconGrid key={layoutName + i} {...layout} />;
        } else if (layoutName === 'CareerListings') {
          return <CareerListings key={layoutName + i} {...layout} />;
        } else if (layoutName === 'CaseStudySlider') {
          return <CaseStudySlider key={layoutName + i} {...layout} />;
        } else if (layoutName === 'CategoryList') {
          return <CategoryList key={layoutName + i} {...layout} />;
        } else if (layoutName === 'FunnelDiagram') {
          return <FunnelDiagram key={layoutName + i} {...layout} />;
        } else if (layoutName === 'IconTextGrid') {
          return <IconTextGrid key={layoutName + i} {...layout} />;
        } else if (layoutName === 'LatestNews') {
          return <LatestNews key={layoutName + i} {...layout} />;
        } else if (layoutName === 'TabAccordion') {
          return <TabAccordion key={layoutName + i} {...layout} />;
        } else if (layoutName === 'Divider') {
          return <Divider key={layoutName + i} {...layout} />;
        } else if (layoutName === 'Principles') {
          return <Principles key={layoutName + i} {...layout} />;
        } else if (layoutName === 'HtmlEmbed') {
          return <HtmlEmbed key={layoutName + i} {...layout} />;
        } else {
          console.log('IMPLEMENT ', layoutName, layout);
        }
        return null;
      })}
    </>
  );
};
