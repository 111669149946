import React from "react";
import { TextContent } from '../TextContent';
import Image from "../../elements/Image";


export const TwoColumnCards = ({
  columnOne,
  columnTwo,
}) => {

  // console.log(columnOne);
  // console.log(columnTwo);
  return(
    <div className="grid grid-cols-1 md:grid-cols-2 gap-y-6 md:gap-y-0 md:gap-x-6">
        {columnOne.cardContentType === 'text' ? 
          <ContentCard card={columnOne}/>
        :
          <MediaCard card={columnOne}/>
        }
        {columnTwo.cardContentType === 'text' ? 
          <ContentCard card={columnTwo}/>
        :
          <MediaCard card={columnTwo}/>
        }
    </div>
  );
};

export const ContentCard = ({
  card
}) =>{

  const cardClasses = ['rounded-[2rem] p-6 md:px-8 xl:px-16 py-12 md:py-20 lg:py-24 xl:py-[8.75rem] flex items-center '];
  if(card.backgroundColour === 'darkGreen'){
    cardClasses.push(' bg-darkGreen text-white');
  } else if(card.backgroundColour === 'maroon'){
    cardClasses.push(' bg-maroon text-white');
  } else if(card.backgroundColour === 'darkTaupe'){
    cardClasses.push(' bg-darkTaupe');
  } else if(card.backgroundColour === 'darkBlue'){
    cardClasses.push(' bg-darkBlue text-white');
  } else if(card.backgroundColour === 'blue'){
    cardClasses.push(' bg-blue');
  } else if(card.backgroundColour === 'midGreen'){
    cardClasses.push(' bg-midGreen');
  } else if(card.backgroundColour === 'white'){
    cardClasses.push(' bg-white');
  }

  return(
    <div className={`${cardClasses}`}>
      <TextContent innerOnly {...card.cardContent} />
    </div>
  );
};

export const MediaCard = ({
  card
}) =>{
  // console.log(card.mediaItem)
  return(
    <div className="rounded-[2rem]  overflow-hidden safari-overflow relative max-h-[580px]">
        <Image image={card.mediaItem.image} objectFit={'cover'} className={`w-full h-full object-cover object-center`} />
    </div>
  );
};