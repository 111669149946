import React from "react";
import Image from "~/components/elements/Image";
import RoundChart from "../../elements/charts/RoundChart";
import { Check } from '../../elements/Icon';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const PropertyCard = ({
  card,
  delayProp
 }) => {

  return(
    <InViewAnimator className="flex space-x-4 mr-6 property-card"
      inner={(controls) => (
        <>
         <InViewAnimatorBasicItem
            controls={controls}
            delay={delayProp/4}
            duration={.5}
            prop='scale'
            start={0}
            end={1}>
            <div className="w-[100px] h-[100px] rounded-lg overflow-hidden safari-overflow">
              <Image image={card.image} objectFit={'cover'} className={`w-full h-full object-cover object-center img-cover`} />
            </div>
          </InViewAnimatorBasicItem>
          <div className="rounded-lg bg-white px-10 py-6 flex space-x-6 items-center">
            <div className="w-12 h-12 mx-auto">
              <RoundChart value={card.value} />
            </div>
            <div>
              <h4 className="p-large font-body font-semibold text-xs !tracking-wide">{card.heading}</h4>
              <div className="flex items-center space-x-2 mt-1">
                <p className="text-midGreen text-xs">{card.message}</p><Check/>
              </div>
            </div>
          </div>
        </>
      )}
    />
  );
};