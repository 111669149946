import React from 'react';
import Marquee from 'react-fast-marquee';
import Image from '~/components/elements/Image';

export const LogoBar = ({ layout = 'rounded-border', logoItems }) => {
const [logoRepeated] = React.useState([...logoItems, ...logoItems, ...logoItems, ...logoItems]);

  return (
    <div className={layout === 'rounded-border' ? `max-w-[96%] mx-auto` : ''}>
      <div
        className={`container ${
          !layout || layout === 'rounded-border'
            ? ' rounded-3xl border border-fadedGrey '
            : layout === 'top-border'
            ? 'border-t border-t-[#1F3F43] border-solid'
            : layout === 'top-border-light'
            ? 'border-t border-t-[#CDD1D0] border-solid'
            : ''
        } ${layout === 'top-border' || layout === 'no-border' ? '!max-w-[100%] ' : ''} !px-0 py-6 md:py-8`}
      >
        <Marquee gradient={false} className="items-center">
          {logoRepeated
            ? logoRepeated?.map((logo, i) => (
                <div key={`logo${i}`} className="w-20 h-6 md:w-32 md:h-10 mx-4 md:mx-8 lg:mx-12">
                  <Image image={logo.logo} className={`w-full h-full object-contain object-center`} />
                </div>
              ))
            : null}
        </Marquee>
      </div>
    </div>
  );
};
