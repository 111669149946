import React from "react";
import { PieChart } from "react-minimal-pie-chart";

const RoundChart = ({
    value
  }) => {

  const chartColour = [];
  if(value > 6){
    chartColour.push('#A8F0DC');
  } else{
    chartColour.push('#FFCF72');
  }
  return (
    <PieChart
    totalValue={10}
    lineWidth={35}
    startAngle={-90}
    rounded={true}
    animate={true}
    background={`#F1F1F1`}
    label={({ dataEntry }) => dataEntry.value}
    labelStyle={{
      fontSize: '1.5rem',
      fontFamily: 'sans-serif',
      fill: '#000',
    }}
    labelPosition={0}
    data={[
      { title: 'One', value: value, color: chartColour },
    ]}
    />
  )
}

export default RoundChart;