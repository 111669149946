import { Link } from 'gatsby';
import React from 'react';
// import Image from '../elements/Image';

export function IconTextGrid({ items }) {
  return (
    <div className="container !max-w-[1280px]">
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {items.map((item) => (
          <div key={`${item?.link?.title}`}>
            <Link
              to={item.link.url}
              className={`h-full py-6 px-4 lg:px-6 bg-white rounded-xl flex items-center space-x-8 transition-all duration-200 ${
                !item.link.url || item?.link?.url === '#' ? 'pointer-events-none' : 'hover:bg-grey'
              } border border-solid border-[#EBE6EA]`}
            >
              {/* <div className="w-full max-w-[70px] flex-[0_0_70px] md:max-w-[104px] md:flex-[0_0_104px]">
                <Image image={item.image} />
              </div> */}

              <div className="flex-auto">
                <div className="t-24 leading-tight" dangerouslySetInnerHTML={{ __html: item?.link?.title }} />
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
