import { graphql, useStaticQuery } from 'gatsby';

export const useResources = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpPost {
        nodes{
          id
          title
          date
          uri
          isSticky
          categories {
            nodes {
              name
            }
          }
          # featuredImage
          author {
            node {
              authorDisplayInformation {
                displayDetails {
                  name
                  role
                  image {
                    altText
                    publicUrl
                    sourceUrl
                    localFile {
                      publicURL
                    }
                  }
                }
              }
            }
          }
          postTypePost {
            summary {
              summary
              image {
                altText
                publicUrl
                sourceUrl
                localFile {
                  publicURL
                }
              }
              externalPost
              externalLink
            }
          }
        }
      }
    }
  `);

  return data.allWpPost?.nodes;
};
