import React from 'react';
import { TextContent } from '../TextContent';
import Image from '../../elements/Image';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const TwoColumnFullBleed = ({
  profilePictures,
  twoColContent,
  image,
}) => {

  const contentClasses = [];
  const mediaClasses = [];

  contentClasses.push('order-first lg:col-start-2 px-[15px] pt-10 pb-6 md:pl-8');
  mediaClasses.push('order-last h-full');

  return (
    <InViewAnimator className="container grid grid-cols-12 items-center gap-y-8 md:gap-y-0 md:gap-x-8 !px-0 !mr-0"
      inner={(controls) => (
        <> 
          <div className={`col-span-12 md:col-span-6 ${contentClasses}`}>
            <div className='flex flex-row mb-6 md:mb-8 justify-center md:justify-start'>
              {profilePictures.map((image, i) => (
                <InViewAnimatorBasicItem className={`w-10 h-10 md:w-[58px] md:h-[58px] ${i < 1 ? '' : '-ml-2 md:-ml-4'}`}
                  controls={controls}
                  delay={i/4}
                  duration={1.5}
                  prop="x"
                  start={-30}
                  key={i} >
                   <Image image={image.images} className={`w-full h-full object-contain object-center`} />
                </InViewAnimatorBasicItem>
              ))}
            </div>
            <TextContent innerOnly {...twoColContent} />
          </div>
          <div className={`col-span-12 md:col-span-6 lg:col-span-5 ${mediaClasses}`}>
            <div className='w-full h-full lg:h-[605px]'>
              <Image image={image} objectFit={'cover'} className={`w-full h-full !object-contain xl:object-cover object-center`} />
            </div>
          </div>
        </>
      )}
    />
  );
};



