import React, { useEffect, useState, useRef } from 'react';
// Animation
import { AnimatePresence, motion } from 'framer-motion';
import { useInViewStart } from '~/hooks/useInViewStart';
// Content
import { TextContent } from '~/components/flexible/TextContent';
// Link
import { HeightFixer } from '~/components/elements/Animator';

export const Faqs = ({ faqs, faqContent }) => {
  const [ref, started] = useInViewStart();
  const [active, set_active] = useState(0);

  return (
    <div className="container center-swiper">
      <div ref={ref} className="grid grid-cols-12 gap-y-8 md:gap-x-8">
        <div className="col-span-12 md:col-span-6 lg:pl-12">
          <TextContent innerOnly {...faqContent} />
        </div>
        <div className="flex flex-col flex-1 col-span-12 md:col-span-7 lg:col-span-6 lg:pr-12">
          <HeightFixer extraHeight={60} extraHeightMobile={140}>
            {faqs.map((item, i) => {
              return (
                <Faq
                  key={i}
                  onClick={() => {
                    set_active(i === active ? null : i);
                  }}
                  isActive={i === active}
                  item={item}
                />
              );
            })}
          </HeightFixer>
        </div>
      </div>
    </div>
  );
};

export const Faq = ({ item, isActive, onClick }) => {
  return (
    <div
      className={`relative rounded-2xl border overflow-hidden border-borderGrey bg-white text-darkGreen cursor-pointer mb-4 py-8 px-6 md:pl-[40px] pr-8 ${
        isActive && 'p-6'
      }`}
      onClick={onClick}
    >
      {isActive && (
        <div className="ml-12 md:ml-14 h-[1px] absolute bottom-0 left-0 right-0">
          <AnimatePresence>
            {isActive && (
              <motion.div
                initial={{ width: 0, opacity: 1 }}
                animate={{ width: '100%', opacity: 1 }}
                exit={{ opacity: 0, duration: 0.5 }}
                transition={{
                  opacity: {
                    duration: 1,
                  },
                }}
                className={`h-full top-[100%] left-6`}
              ></motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
      <h4 className="relative flex items-center justify-between space-x-4 duration-500 t-25">
        <span className="max-w-[385px]">{item?.question}</span>
        <ItemIcon status={isActive} />
      </h4>
      <AnimatePresence exitBeforeEnter>
        {isActive && (
          <motion.div
            transition={{ type: 'tween' }}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1, duration: 1.5 }}
            exit={{ height: 0 }}
          >
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, opacity: { delay: 0.2 } }}
              exit={{ opacity: 0, transition: { delay: 0 } }}
              className="relative pt-4 t-16 max-w-[380px]"
            >
              {item?.answer}
            </motion.p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export const ItemIcon = ({ status }) => {
  return (
    <div className="relative w-[12px] h-[12px]">
      <span
        className={`absolute h-full w-[1.6px] bg-darkGreen transition-all duration-200 ${status ? 'rotate-90' : ''}`}
      ></span>
      <span className="absolute h-full w-[1.6px] bg-darkGreen rotate-90"></span>
    </div>
  );
};
