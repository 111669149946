import React, { useRef, useState, useEffect } from 'react';
import { TextContent } from "../TextContent";
import Marquee from "react-easy-marquee";
import Image from "~/components/elements/Image";
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { Link } from 'gatsby';

export const TwoColumnVerticalScroll = ({
  twoColContent,
  scrollingItems,
  scrollCards,
}) => {

 ;
  const width = typeof window !== 'undefined' && window.width;

  const scrollHeight = [];

  if(width < 768){
    scrollHeight.push('320px');
  } else {
    scrollHeight.push('580px');
  }



  const scrollerWidth = [];
  // Scroller MaxWidth controls
  if(scrollCards === 'image'){
    scrollerWidth.push('max-w-[380px] mx-auto');
  } else {
    scrollerWidth.push('max-w-[510px] mx-auto');
  }


  return (
    <InViewAnimator className="container grid grid-cols-12 items-center"
      inner={(controls) => (
        <>
          <div className="col-span-12 md:col-span-6 lg:col-span-5 flex xl:justify-center py-8 md:pr-8">
            <TextContent innerOnly {...twoColContent} />
          </div>
          <div className="col-span-12 md:col-span-6 lg:col-start-7">
            <div className={`${scrollerWidth} vertical-gradients relative scroll-span`}>
              <Marquee  
                duration={20000}
                axis="Y"
                align="center"
                pauseOnHover={true}
                width="100%"
                height={`580px`}
                className='items-center'>
                {scrollingItems.map(( card,i ) => (
                  <ScrollCard card={card} key={i} layout={scrollCards} controls={controls} delayProp={i}/>
                ))}
              </Marquee>
            </div>
          </div>
        </>
      )}
    />
  );
};

export const ScrollCard = ({ 
  card,
  layout,
  delayProp,
  controls,
}) => {

  if(layout === 'image'){
    return(
      <div className="rounded-xl overflow-hidden w-[96%] mb-4 safari-overflow">
        <InViewAnimatorBasicItem
            controls={controls}
            delay={delayProp/4}
            duration={.5}
            prop='scale'
            start={0}
            end={1}>
            <Image image={card.image} className={`w-full h-full object-contain object-center`} />
          </InViewAnimatorBasicItem>
      </div>
    );
  } else {
    return(
      <div className="rounded-xl border border-borderGrey bg-white text-darkGreen w-[96%] mb-4">
        <Link className='flex link-scroll-card items-center px-8 py-6 hover:opacity-60 transition-all duration-200'
          to={card?.link?.url || '#'}>
        <div className="w-10 h-10 lg:w-[50px] lg:h-[50px] mr-6">
        <InViewAnimatorBasicItem
            controls={controls}
            delay={delayProp/4}
            duration={.5}
            prop='scale'
            start={0}
            end={1}>
            <Image image={card.image} className={`w-full h-full object-contain object-center`} />
          </InViewAnimatorBasicItem>
        </div>
        <h5 className="text-h5">{card.header}</h5>
        </Link>
      </div>
    );
  };
};