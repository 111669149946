import { Link } from 'gatsby';
import React from 'react';

export function Principles({ heading, content, link, listItems = [] }) {
  return (
    <div className="container ">
      <div className="grid md:grid-cols-2 gap-x-12 xl:gap-x-20 gap-y-12 px-3 md:px-0 py-4 sm:py-8">
        <div className="flex flex-col items-center">
          <div className="space-y-6 md:max-w-[420px]">
            <h3 className="t-58 font-heading" dangerouslySetInnerHTML={{ __html: heading }} />
            <div className="t-18" dangerouslySetInnerHTML={{ __html: content }} />
            <div>
              <Link to={link?.url} className="inline-block btn btn-orange">
                {link?.title}
              </Link>
            </div>
          </div>
        </div>

        <div>
          <ListItems listItems={listItems} />
        </div>
      </div>
    </div>
  );
}

function ListItems({ listItems = [] }) {
  return (
    <div className="max-w-[528px] w-full t-22">
      {listItems.map((item, i) => (
        <div
          key={item.heading}
          className={`flex space-x-5 sm:space-x-6 ${i < listItems?.length - 1 ? 'pb-5 sm:pb-6 mb-5 sm:mb-6' : ''}`}
          style={{ borderBottom: i < listItems?.length - 1 ? '1px solid rgba(255, 255, 255, 0.10)' : '' }}
        >
          <div className="opacity-50">{i + 1 < 10 ? `0${i + 1}` : i + 1}</div>
          <div dangerouslySetInnerHTML={{ __html: item.heading }} />
        </div>
      ))}
    </div>
  );
}
