import React from 'react';
import { TextContent } from '../TextContent';
import { SimpleMedia } from '../media/SimpleMedia';
import { CheckAuto } from '../../elements/Icon';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const TwoColumnSimpleMedia = ({
  twoColContent,
  bulletPoints,
  mediaItem,
  reverse,
  mediaPosition,
  alignContentTop,
}) => {
  const contentClasses = [];
  const mediaClasses = [];
  const mediaPositionClasses = [];

  if (mediaPosition === 'lbtm') {
    mediaPositionClasses.push(
      'ml-[-20px] sm:ml-[-110px] md:ml-[-44px] lg:ml-[-150px] xl:ml-[-270px] mb-[-2.5rem] md:mb-[-5rem]'
    );
  }
  if (mediaPosition === 'rbtm') {
    mediaPositionClasses.push(
      'mr-[-20px] sm:mr-[-110px] md:mr-[-44px] lg:mr-[-150px] xl:mr-[-270px] mb-[-2.5rem] md:mb-[-5rem]'
    );
  }
  if (mediaPosition === 'top') {
    mediaPositionClasses.push('md:mt-[-5rem]');
    mediaClasses.push(' h-full ');
  }
  if (mediaPosition === 'bottom') {
    mediaPositionClasses.push('mb-[-2.5rem] md:mb-[-5rem]');
  }
  if (mediaPosition === 'left') {
    mediaPositionClasses.push('ml-[-20px] sm:ml-[-110px] md:ml-[-44px] lg:ml-[-150px] xl:ml-[-270px]');
  }

  if (reverse) {
    contentClasses.push('order-first md:order-last');
    mediaClasses.push('order-last md:order-first');
  } else {
    contentClasses.push('order-first lg:col-start-2');
    mediaClasses.push('order-last');
  }

  return (
    <div
      className={`container grid grid-cols-12 ${!alignContentTop ? 'items-center' : ''} gap-y-8 md:gap-y-0 md:gap-x-8`}
    >
      <div className={`col-span-12 md:col-span-6 lg:col-span-5 ${contentClasses}`}>
        <TextContent innerOnly {...twoColContent} />
        {bulletPoints && (
          <InViewAnimator
            className="flex flex-col sm:flex-row justify-center sm:justify-between gap-y-4 sm:gap-y-0 sm:gap-x-4 mt-6 md:mt-[40px] max-w-[320px] mx-auto"
            inner={(controls) => (
              <>
                {bulletPoints?.map((point, i) => (
                  <div className="flex justify-center sm:justify-left items-center gap-x-2">
                    <InViewAnimatorBasicItem
                      className="w-[20px] h-[20px]"
                      controls={controls}
                      delay={i / 3}
                      duration={0.5}
                      prop="scale"
                      start={0}
                      end={1}
                    >
                      <CheckAuto />
                    </InViewAnimatorBasicItem>
                    {point?.text}
                  </div>
                ))}
              </>
            )}
          />
        )}
      </div>
      <div className={`col-span-12 md:col-span-6 ${mediaClasses}`}>
        <div className="sm:w-3/4 mx-auto md:w-full">
          <div className={`${mediaPositionClasses}`}>
            <SimpleMedia simpleMedia={mediaItem.simpleMedia} />
          </div>
        </div>
      </div>
    </div>
  );
};
