import React from 'react';
import Image from '../../elements/Image';
import { TextContent } from '../TextContent';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

const TextMasthead = ({ 
  textMastheadContent,
}) => {

  return (
    <InViewAnimator className="container pt-8 md:pt-[4.5rem]"
      inner={(controls) => (
        <InViewAnimatorBasicItem
            controls={controls}
            delay={1/4}
            duration={1.2}
            prop={'y'}
            start={'30'}>
          <TextContent innerOnly {...textMastheadContent} />
        </InViewAnimatorBasicItem>
      )}
    />
  );
};

export default TextMasthead;
