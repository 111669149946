import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Image from '~/components/elements/Image';
import { motion } from 'framer-motion';

export const CaseStudyListing = (props) => {
  const { heading, post_type, count } = props

  const parentFrame = {
    hidden: {
      opacity: 0
    },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: .3
      }
    }
  }

  const childrenFrame = {
    hidden: {
      y: 60
    },
    show: {
      y: 0,
      transition: {
        duration: 1,
      }
    }
  }

  const data = useStaticQuery(graphql`
    query CaseStudyQuery {
      allWpCaseStudy {
        nodes {
          ...GeneratedWpCaseStudy
        }
      }
    }
  `)
  return (
    <section className="section-lrg mt-8 md:mt-[70px]">
      <h3 className="t-heading-35 mb-10 text-center md:text-left">{heading}</h3>
      <motion.div
        variants={parentFrame}
        initial="hidden"
        whileInView="show"
        className='grid sm:grid-cols-2 lg:grid-cols-3 gap-6'>
        {
          data?.allWpCaseStudy?.nodes?.map((post) => {
            return (
              <motion.div
                variants={childrenFrame}
                className="bg-white rounded-[2rem] border-[1px] border-black/10">
                <Link to={post?.uri} className="block p-6 md:p-8 lg:p-[40px] hover:opacity-50 transition-all duration-200">
                  <div className='mb-10'>
                    {
                      post?.featuredImage && (
                        <Image className="aspect-[302/214] rounded-[20px]"
                               objectFit="cover" objectPosition="center"
                               image={post?.featuredImage?.node}>
                        </Image>
                      )
                    }
                    {
                      !post?.featuredImage && (
                        <div className="aspect-[302/214] rounded-[20px] bg-paleBlue"/>
                      )
                    }
                  </div>
                  
                  <div className="max-w-[75px] h-[1.5rem] mb-6">
                    {post?.postTypeCaseStudy?.caseStudySummary?.logo &&
                      <Image className="w-full h-full object-contain object-center" image={post?.postTypeCaseStudy?.caseStudySummary?.logo} />
                      }
                  </div>
                 
                  <h3 className="t-heading-30 mb-8">{post?.title}</h3>
                  <span className="text-coral t-19">Read story</span>
                </Link>
              </motion.div>

            )
          })
        }
      </motion.div>
    </section>
  )
}