import React from 'react';
import { Button } from './Button';

const ButtonGroup = ({ links }) => {
  return (
    <div className="md:flex space-y-2.5 md:space-y-0 md:space-x-[15px] w-full md:w-auto">
      {links?.map((link, i) => (
        <Button
          key={i}
          type={link?.type}
          link={link?.link}
          color={link?.buttonColour}
          removeArrow={link?.removeArrow}
        />
      ))}
    </div>
  );
};

export default ButtonGroup;
