import React from "react";
import Image from "~/components/elements/Image";
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const CaseStudyPreview = ( {
  caseStudy,
  offsetImage,
  centerImage,
}) =>{

  const preview = caseStudy.postTypeCaseStudy.caseStudySummary;
  
  return(
    <InViewAnimator className="container grid grid-cols-12 items-center"
      inner={(controls) => (
        <>
          <div className="col-span-12 md:col-span-6 lg:col-start-1 lg:pl-16 space-y-6 md:space-y-8 text-center md:text-left">
            {preview.logo &&
              <div className="w-[130px] h-[20px] mx-auto md:ml-0">
                <Image image={preview.logo} className={`w-full h-full object-contain object-center md:object-left`} />
              </div>
            }
            <blockquote className="t-24 max-w-[594px] mx-auto md:ml-0" dangerouslySetInnerHTML={{__html: preview.quote}}/>
            <header className="lg:flex lg:space-x-2">
              {preview?.name &&
                <p className="font-semibold">{preview.name},</p>
              }
              <p className="font-semibold">{preview.role}</p>
              <p>{preview.company}</p>
            </header>
          </div>
          <div className="col-span-12 md:col-span-6 lg:col-span-5 mt-6 md:mt-0">
            <InViewAnimatorBasicItem className={`max-w-[245px] md:max-w-[230px] md:min-h-[350px] flex items-end mx-auto bg-yellow rounded-t-[8rem] rounded-b-2xl safari-overflow pt-6 md:pt-8 lg:pt-12 overflow-hidden safari-overflow`}
              controls={controls}
              delay={.5}
              duration={1}>
                <div className={`w-full h-full`}>
                <InViewAnimatorBasicItem className='w-full h-full'
                  controls={controls}
                  delay={.8}
                  duration={1.5}
                  prop="x"
                  start={30}>
                <Image image={preview.previewImage} className={``} />
              </InViewAnimatorBasicItem>
              </div>
            </InViewAnimatorBasicItem>
          </div>
        </>
      )}
    />
  );
};