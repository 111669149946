import React, { useRef, useCallback } from 'react';
import Image from '~/components/elements/Image';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation, Pagination } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

import { SwiperArrow } from '../../elements/Icon';

export const CaseStudySlider = ({ caseStudies, layout = 'v1' }) => {
  if (!layout || layout === 'v1') {
    return <CaseStudySliderV1 caseStudies={caseStudies} />;
  }

  if (layout === 'v2') {
    return <CaseStudySliderV2 caseStudies={caseStudies} />;
  }
};

export const CaseStudySliderV2 = ({ caseStudies }) => {
  const caseStudySwiper = useRef(null);

  const handlePrev = useCallback(() => {
    if (!caseStudySwiper.current) return;
    caseStudySwiper.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!caseStudySwiper.current) return;
    caseStudySwiper.current.swiper.slideNext();
  }, []);

  return (
    <div className="relative">
      <Swiper
        modules={[Navigation, Pagination, EffectFade]}
        effect="fade"
        fadeEffect={{ crossFade: false }}
        spaceBetween={20}
        loop={true}
        slidesPerView={'auto'}
        ref={caseStudySwiper}
        className="flex"
      >
        {caseStudies?.map((caseStudy, i) => (
          <SwiperSlide key={`case${i}`} className="flex flex-col !h-auto">
            <CaseStudyCardV2 caseStudy={caseStudy?.postTypeCaseStudy} prevFunc={handlePrev} nextFunc={handleNext} />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* <div className="max-w-[1010px] xl:max-w-[1200px] h-[30px] px-4 sm:px-6 md:px-8 m-auto absolute z-10 top-auto bottom-12 md:bottom-16 lg:bottom-20 left-0 right-0 flex justify-center md:justify-start space-x-4">
          <div className={`${SwiperButton} rotate-180`} onClick={handlePrev} >
              <SwiperArrow />
          </div>
          <div className={`${SwiperButton}`} onClick={handleNext}>
              <SwiperArrow />
          </div>
        </div> */}
    </div>
  );
};

export const CaseStudyCardV2 = ({ caseStudy, prevFunc, nextFunc }) => {
  const preview = caseStudy.caseStudySummary;
  return (
    <div className="bg-darkestGreen flex-auto flex flex-col">
      <div className="flex-auto flex flex-col max-w-[1000px] w-full mx-auto px-6 py-12 md:py-16 lg:pt-20 lg:pb-24 ">
        {/* Content */}
        <div className="flex-auto p-1">
          <svg
            className="mx-auto md:ml-0"
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="18"
            viewBox="0 0 23 18"
            fill="none"
          >
            <path
              d="M5.39796 18C2.19048 18 0 15.4397 0 11.4828C0 9.15518 0.704082 6.98276 2.19048 4.88793C3.9898 2.40517 6.88435 0 8.52721 0C9.38776 0 9.85714 0.620691 9.85714 1.31897C9.85714 1.7069 9.54422 2.01724 8.9966 2.40517C6.80612 3.87931 5.71088 5.43103 5.71088 7.06035C5.71088 8.06897 5.86735 8.22414 7.5102 9.15518C9.07483 10.0086 10.3265 11.7931 10.3265 13.4224C10.3265 15.8276 7.97959 18 5.39796 18ZM12.8299 9.38793C13.534 6.28449 15.2551 3.72414 17.915 1.62931C19.2449 0.543106 20.3401 0 21.1224 0C21.983 0 22.5306 0.620691 22.5306 1.31897C22.5306 1.7069 22.2177 2.01724 21.5918 2.40517C19.4796 3.87931 18.3844 5.43103 18.3844 7.06035C18.3844 8.06897 18.619 8.30172 20.1054 9.15518C21.7483 10.0086 23 11.7931 23 13.3448C23 15.8276 20.8095 18 18.0714 18C14.7075 18 12.6735 15.4397 12.6735 11.4828C12.6735 10.6293 12.7517 10.0862 12.8299 9.38793Z"
              fill="#97E1FC"
            />
          </svg>
          <blockquote
            className="t-28 text-center md:text-left max-w-[880px] mt-6 md:mt-8 lg:mt-10 leading-[1.3] space-y-7"
            dangerouslySetInnerHTML={{ __html: preview.quote }}
          />
          <svg
            className="mx-auto md:ml-0 -scale-100 mt-6 md:mt-8"
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="18"
            viewBox="0 0 23 18"
            fill="none"
          >
            <path
              d="M5.39796 18C2.19048 18 0 15.4397 0 11.4828C0 9.15518 0.704082 6.98276 2.19048 4.88793C3.9898 2.40517 6.88435 0 8.52721 0C9.38776 0 9.85714 0.620691 9.85714 1.31897C9.85714 1.7069 9.54422 2.01724 8.9966 2.40517C6.80612 3.87931 5.71088 5.43103 5.71088 7.06035C5.71088 8.06897 5.86735 8.22414 7.5102 9.15518C9.07483 10.0086 10.3265 11.7931 10.3265 13.4224C10.3265 15.8276 7.97959 18 5.39796 18ZM12.8299 9.38793C13.534 6.28449 15.2551 3.72414 17.915 1.62931C19.2449 0.543106 20.3401 0 21.1224 0C21.983 0 22.5306 0.620691 22.5306 1.31897C22.5306 1.7069 22.2177 2.01724 21.5918 2.40517C19.4796 3.87931 18.3844 5.43103 18.3844 7.06035C18.3844 8.06897 18.619 8.30172 20.1054 9.15518C21.7483 10.0086 23 11.7931 23 13.3448C23 15.8276 20.8095 18 18.0714 18C14.7075 18 12.6735 15.4397 12.6735 11.4828C12.6735 10.6293 12.7517 10.0862 12.8299 9.38793Z"
              fill="#97E1FC"
            />
          </svg>
        </div>

        <header className="mt-6 md:mt-12 lg:mt-16 flex flex-col md:flex-row items-center justify-between">
          <div className="flex space-x-3 md:space-x-4">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-[28px] md:w-[32px]" viewBox="0 0 32 32" fill="none">
              <path
                d="M2 27H5M30 27H27M5 27V11.5352C5 11.2008 5.1671 10.8886 5.4453 10.7031L15.4453 4.03647C16.1099 3.59343 17 4.06982 17 4.86852V12M5 27H17M17 27V12M17 27H27M17 12H26C26.5523 12 27 12.4477 27 13V27"
                stroke="#97E1FC"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path d="M9 14V16" stroke="#97E1FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M9 20V22" stroke="#97E1FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M13 14V16" stroke="#97E1FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M13 20V22" stroke="#97E1FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <p className="text-blue t-24 relative top-[2px] md:top-0">{preview?.company}</p>
          </div>

          <div className="flex space-x-3 mt-6 md:mt-0">
            <svg
              onClick={prevFunc}
              className="transition-opacity duration-150 hover:opacity-100 opacity-80 cursor-pointer transform rotate-180"
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="auto"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g>
                <circle cx="12" cy="12" r="12" fill="#F66B4C" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.2378 8.1591L16.8345 11.6159C17.0552 11.828 17.0552 12.172 16.8345 12.3841L13.2378 15.8409C13.0171 16.053 12.6592 16.053 12.4385 15.8409C12.2178 15.6288 12.2178 15.2849 12.4385 15.0727L15.0704 12.5432H6V11.4568H15.0704L12.4385 8.92728C12.2178 8.71515 12.2178 8.37122 12.4385 8.1591C12.6592 7.94697 13.0171 7.94697 13.2378 8.1591Z"
                  fill="white"
                />
              </g>
            </svg>
            <svg
              onClick={nextFunc}
              className="transition-opacity duration-150 hover:opacity-100 opacity-80 cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="auto"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g>
                <circle cx="12" cy="12" r="12" fill="#F66B4C" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.2378 8.1591L16.8345 11.6159C17.0552 11.828 17.0552 12.172 16.8345 12.3841L13.2378 15.8409C13.0171 16.053 12.6592 16.053 12.4385 15.8409C12.2178 15.6288 12.2178 15.2849 12.4385 15.0727L15.0704 12.5432H6V11.4568H15.0704L12.4385 8.92728C12.2178 8.71515 12.2178 8.37122 12.4385 8.1591C12.6592 7.94697 13.0171 7.94697 13.2378 8.1591Z"
                  fill="white"
                />
              </g>
            </svg>
          </div>
        </header>
      </div>
    </div>
  );
};

export const CaseStudySliderV1 = ({ caseStudies, layout = 'v1' }) => {
  const caseStudySwiper = useRef(null);

  const handlePrev = useCallback(() => {
    if (!caseStudySwiper.current) return;
    caseStudySwiper.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!caseStudySwiper.current) return;
    caseStudySwiper.current.swiper.slideNext();
  }, []);

  const SwiperButton = [
    ' rounded-[8px] relative z-20 inline-flex items-center justify-center px-4 py-[12px] bg-white cursor-pointer hover:bg-yellow transition-all duration-200 ',
  ];

  return (
    <div className="relative">
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={20}
        loop={true}
        slidesPerView={'auto'}
        pagination={{
          type: 'progressbar',
        }}
        ref={caseStudySwiper}
      >
        {caseStudies?.map((caseStudy, i) => (
          <SwiperSlide key={`case${i}`}>
            <CaseStudyCardV1 caseStudy={caseStudy?.postTypeCaseStudy} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="max-w-[1010px] xl:max-w-[1200px] h-[30px] px-4 sm:px-6 md:px-8 m-auto absolute z-10 top-auto bottom-12 md:bottom-16 lg:bottom-20 left-0 right-0 flex justify-center md:justify-start space-x-4">
        <div className={`${SwiperButton} rotate-180`} onClick={handlePrev}>
          <SwiperArrow />
        </div>
        <div className={`${SwiperButton}`} onClick={handleNext}>
          <SwiperArrow />
        </div>
      </div>
    </div>
  );
};

export const CaseStudyCardV1 = ({ caseStudy }) => {
  const preview = caseStudy.caseStudySummary;
  return (
    <InViewAnimator
      className="max-w-[1010px] xl:max-w-[1200px] mx-auto flex flex-col-reverse md:flex-row md:items-center pt-10 md:pt-[72px] px-4 sm:px-6 md:px-8 pb-24 md:pb-[120px]"
      inner={(controls) => (
        <>
          {/* Content */}
          <div>
            {preview.logo && (
              <div className="w-[130px] h-[26px] mx-auto md:ml-0 mt-6 md:mt-0">
                <Image image={preview.logo} className={`w-full h-full object-contain object-center md:object-left`} />
              </div>
            )}
            <blockquote
              className="t-24 text-center md:text-left max-w-[585px] mx-auto md:ml-0 leading-[1.15] mt-6"
              dangerouslySetInnerHTML={{ __html: preview.quote }}
            />
            <header className="flex flex-col lg:flex-row lg:space-x-2 justify-center md:justify-start text-center md:text-left mt-6 md:mt-8">
              {preview?.name && <p className="font-semibold">{preview.name} —</p>}
              <p className="font-semibold">{preview.role}</p>
              <p>{preview.company}</p>
            </header>
          </div>
          {/* Image */}
          <div className="w-full max-w-[310px] mx-auto md:mr-0">
            <div
              className={`max-w-[200px] sm:max-w-[245px] h-[300px] sm:h-[320px] md:h-[350px] lg:h-[394px] flex items-end mx-auto bg-yellow rounded-t-[8rem] rounded-b-2xl safari-overflow pt-6 md:pt-8 lg:pt-12 overflow-hidden`}
            >
              <div className="w-full max-h-[100%]">
                <Image image={preview.previewImage} className={`w-full h-full object-contain object-bottom`} />
              </div>
            </div>
          </div>
        </>
      )}
    />
  );
};
