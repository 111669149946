import React, { useState } from "react";
import { ButtonArrow } from "../buttons/Button";
import axios from "axios";

export const Form = ({

}) => {

  const [ formSent, setFormStatus ] = useState('initial')

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/228d9162-a439-4a69-8579-aff0d3b6ef56",
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, "Thanks!", form);
        console.log('success');
        setFormStatus('success');
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
        console.log('fail');
        setFormStatus('fail');
      });
  };

  return(
  <form className="text-left"
    onSubmit={handleOnSubmit}>
      <div className="gap-5 md:flex">
        <fieldset className="my-2 md:w-1/2">
          <label className="mb-4 t-16">Full Name</label>
          <input
            className="w-full px-4 md:px-6 py-3 rounded-[10px] flex-1 my-2 leading-4 bg-white border-borderGrey focus:!border-midGreen border transition-all duration-200"
            type="text"
            required
            name="Full Name"
          />
        </fieldset>
        <fieldset className="my-2 md:w-1/2">
          <label className="mb-4 t-16">Email Address</label>
          <input
            className="w-full px-4 md:px-6 py-3 rounded-[10px] flex-1 my-2 leading-4 bg-white border-borderGrey focus:!border-midGreen border transition-all duration-200"
            type="email"
            required
            name="Email"
          />
        </fieldset>
      </div>
      <div className="gap-5 md:flex">
        <fieldset className="w-full mt-2 mb-4">
          <label className="mb-4 t-16">Message</label>
          <textarea name="Message" className="w-full min-h-[144px] px-4 md:px-6 py-3 rounded-md flex-1 my-2 leading-4 bg-white border-borderGrey focus:!border-midGreen !outline-none border transition-all duration-200"></textarea>
        </fieldset>
      </div>
      <fieldset className="flex items-start w-full gap-3 mt-5 mb-8 max-w-[575px]">
        <input name="Privacy" type="checkbox" className="relative top-1 !w-[25px] !h-[14px]" id="privacy" required />
        <label className="t-14 w-[95%] lg:w-full" htmlFor="privacy">
          I accept Setpoint’s <a className="underline text-coral hover:text-darkGreen transition-all duration-200" href='/privacy-policy/'>Privacy Policy</a> and agree to receive promotional messages from Setpoint about its products and services. You can unsubscribe at any time by clicking on the link at the bottom of our emails.
        </label>
      </fieldset>
        <button
          className="btn btn-coral flex items-center space-x-[9px] whitespace-nowrap justify-center mx-auto md:ml-0"
          type="submit"
          >
            <span>Submit message</span>
          
          <ButtonArrow color={'coral'} arrowFill={false}/>
        </button>
        {formSent === 'success' && (
          <div className="t-16 max-w-[395px] text-center mx-auto mt-4">
            <span className="font-bold">Thanks for your message. </span> A member of the Setpoint team will be in touch.
          </div>
        )}
    </form>
  );
};