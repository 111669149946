import React from 'react';
import Image from '../elements/Image';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { LinkedinWhite } from '~/components/elements/Icon';
import { Link } from 'gatsby';

export const TeamList = ({ team, layout }) => {
  if (layout === 'no-image') {
    return (
      <InViewAnimator
        className="container !max-w-[1200px] grid grid-cols-2 lg:grid-cols-3 gap-x-4 sm:gap-x-6 md:gap-x-8 gap-y-8 lg:gap-y-[54px]"
        inner={(controls) => (
          <>
            {team.map((member, i) => (
              <TeamSingleNoImage key={i} member={member} staggerDelay={i} controls={controls} />
            ))}
          </>
        )}
      />
    );
  }

  return (
    <InViewAnimator
      className="container !max-w-[1200px] grid grid-cols-2 lg:grid-cols-4 gap-x-4 sm:gap-x-6 md:gap-x-8 gap-y-8 lg:gap-y-[54px]"
      inner={(controls) => (
        <>
          {team.map((member, i) => (
            <TeamSingle key={i} member={member} staggerDelay={i} controls={controls} />
          ))}
        </>
      )}
    />
  );
};

export const TeamSingle = ({ member, staggerDelay, controls }) => {
  return (
    <InViewAnimatorBasicItem controls={controls} delay={staggerDelay / 4} duration={1} key={staggerDelay}>
      <div className="w-full max-w-[258px] bg-blue rounded-t-[8rem] rounded-b-2xl overflow-hidden safari-overflow mx-auto relative">
        <InViewAnimatorBasicItem
          className="w-full h-full"
          controls={controls}
          delay={(staggerDelay + 1) / 4}
          duration={1.5}
          prop="x"
          start={30}
          key={staggerDelay}
        >
          <Image
            image={member.image}
            objectFit={'cover'}
            className={`w-full h-[58vw] sm:h-full sm:min-h-[380px] max-h-[380px] md:min-h-[380px] object-cover object-center scale-[1.01]`}
          />
        </InViewAnimatorBasicItem>
      </div>
      <div className="text-center mt-4 md:mt-[30px] flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 justify-center items-center">
        <div>
          <h6 className="t-18 sm:t-20">{member.name}</h6>
          <p className="t-14 sm:t-16">{member.role}</p>
        </div>
        {member?.linkedin && (
          <div className="bg-coral w-[24px] h-[24px] rounded-md hover:!scale-[1.1] transition-all duration-300">
            <Link className="flex items-center justify-center w-full h-full" to={member?.linkedin} target="_blank">
              <div className="w-[14px] h-[15px] m-auto">
                <LinkedinWhite classes={'w-full h-auto object-contain object-center'} />
              </div>
            </Link>
          </div>
        )}
      </div>
    </InViewAnimatorBasicItem>
  );
};

export const TeamSingleNoImage = ({ member, staggerDelay, controls }) => {
  return (
    <InViewAnimatorBasicItem controls={controls} delay={staggerDelay / 4} duration={1} key={staggerDelay}>
      <div className="bg-white p-4 rounded-md">
        <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 items-center justify-between">
          <div>
            <h6 className="t-20">{member.name}</h6>
            <p className="t-18 mt-1 text-[#515F62]">{member.role}</p>
          </div>
          {member?.linkedin && (
            <div className="bg-orange w-[24px] h-[24px] rounded-md hover:!scale-[1.1] transition-all duration-300">
              <Link className="flex items-center justify-center w-full h-full" to={member?.linkedin} target="_blank">
                <div className="w-[14px] h-[15px] m-auto">
                  <LinkedinWhite classes={'w-full h-auto object-contain object-center'} />
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </InViewAnimatorBasicItem>
  );
};
