import React from "react";
import Image from "~/components/elements/Image";

export const WordCard = ({
  card
 }) => {

  return(
    <div className="flex items-center space-x-[20px] mr-[20px]">
      <div className="w-[30px] md:w-[55px] h-[30px] md:h-[55px]">
        <Image image={card.icon} className={`w-full h-full object-contain object-center`} />
      </div>
      <div className="block pt-1 md:pt-2">
        <h2 className="text-h2">{card.message}</h2>
      </div>
    </div>
  );
};