import React from "react";
import Image from "~/components/elements/Image";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const SimpleMedia = ({
  simpleMedia,
  marginClasses
}) => {

  return(
    <InViewAnimator
      className={simpleMedia?.className }
      inner={(controls) => (
        <>
          {simpleMedia.animateUp ?
            <div className={`mx-auto px-6 ${marginClasses}`} {...maxWidthProps(simpleMedia.maxwidth)}>
              {simpleMedia.mediaType === 'image' &&
                <InViewAnimatorBasicItem
                  controls={controls}
                  delay={.2}
                  duration={.8}
                  prop='y'
                  start={50}>
                  <Image image={simpleMedia.image} className={`w-full h-full object-contain object-center`} />
                </InViewAnimatorBasicItem>
              }
            </div>
          :
            <div className={`mx-auto ${marginClasses}`} {...maxWidthProps(simpleMedia.maxwidth)}>
              {simpleMedia.mediaType === 'image' &&
                <Image image={simpleMedia.image} className={`w-full h-full object-contain object-center`} />
              }
            </div>
          }
        </>
      )}
    />
  );
};

export const maxWidthProps = (setting) => {
  if (!setting) return {};

  const { width } = useWindowSize();
  return {
    style: {
      maxWidth: width > 768 ? `${setting}px` : null,
    },
  };
};