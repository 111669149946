import React from 'react';
import Image from '~/components/elements/Image';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const CaseStudyLogoBlock = ({ alignment, caseStudyItem}) => {
  const summary = caseStudyItem[0]?.postTypeCaseStudy?.caseStudySummary
  return (
    <InViewAnimator className='section-lrg'
    inner={(controls) => (
      <>
        <div className={`bg-white rounded-4xl grid grid-cols-12 items-center overflow-hidden safari-overflow order-last ${alignment === "left" ? 'md:order-first' : ' md:order-last'}`}>
          <div className="p-8 lg:py-12 lg:px-24 xl:px-[128px] xl:py-[110px] w-full max-w-[1200px] col-span-12 md:col-span-7">
            <h3 className="font-heading t-40 block leading-[1.1]" dangerouslySetInnerHTML={{__html: summary?.subheading}}/>
            <hr className='opacity-20 mt-6 md:mt-8' />
            <div className="font-body t-22 block my-4 md:my-8 leading-[1.1]" dangerouslySetInnerHTML={{__html: summary?.quote}}/>
            <cite className="not-italic font-bold t-18">{summary?.name}, {`${summary.company}` }</cite>
          </div>
          <div className={`bg-darkGreen w-full px-8 h-full min-h-[200px] flex items-center justify-center order-first col-span-12 md:col-span-5 ${alignment === "left" ? 'md:order-last' : ' md:order-first'}`}>
            <InViewAnimatorBasicItem className="flex justify-center mx-auto w-full"
              controls={controls}
              delay={2/4}
              duration={1.2}
              prop={'y'}
              start={'30'}>
              <div className="relative w-[220px] lg:w-[240px] max-h-[65px]">
              <Image className="w-full h-full object-contain" image={summary.logoWhite}/>
              </div>
            </InViewAnimatorBasicItem>
          </div>
        </div>
        </>
    )}
    />
  );
}