import React from 'react';
import Image from '~/components/elements/Image';
import { TextContent } from '../TextContent';
import { SimpleMedia } from '../media/SimpleMedia';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const TwoColumnMasthead = (props) => {
  const { layout = 'v1', mastheadContent, mediaItem, reverse, spacing, largerContent } = props;

  if (layout === 'v2') {
    return <MastheadV2 {...props} />;
  }

  const gridClasses = [];
  const contentGridClasses = [];
  const contentClasses = [];
  const mediaClasses = [];
  const mediaProp = [];

  if (reverse) {
    contentClasses.push('order-first md:order-last lg:col-start-7');
    mediaClasses.push('order-last md:order-first');
    mediaProp.push('-30');
  } else {
    contentClasses.push('order-first');
    mediaClasses.push('order-last lg:col-start-7');
    mediaProp.push('30');
  }

  if (spacing === 'large') {
    gridClasses.push('lg:gap-x-10 xl:gap-x-28');
  } else if (spacing === 'mid') {
    gridClasses.push('lg:gap-x-10 xl:gap-x-20');
  } else {
    gridClasses.push('lg:gap-x-10');
  }

  if (largerContent) {
    contentGridClasses.push('lg:col-span-6');
  } else {
    contentGridClasses.push('lg:col-span-5');
  }

  return (
    <InViewAnimator
      className={`grid grid-cols-12 items-center gap-y-8 md:gap-y-0 md:gap-x-8 py-8 md:py-0 ${gridClasses}`}
      inner={(controls) => (
        <>
          <InViewAnimatorBasicItem
            className={`col-span-12 md:col-span-6 ${contentGridClasses}  ${contentClasses}`}
            controls={controls}
            delay={1 / 4}
            duration={1.2}
            prop={'y'}
            start={'30'}
          >
            <TextContent innerOnly {...mastheadContent} />
          </InViewAnimatorBasicItem>
          <InViewAnimatorBasicItem
            className={`col-span-12 md:col-span-6 ${mediaClasses}`}
            controls={controls}
            delay={2 / 4}
            duration={1.2}
            prop={'x'}
            start={mediaProp}
          >
            <div className="sm:w-3/4 mx-auto md:w-full">
              <SimpleMedia simpleMedia={mediaItem.simpleMedia} />
            </div>
          </InViewAnimatorBasicItem>
        </>
      )}
    />
  );
};

function MastheadV2({ mastheadContent, mediaItem }) {
  return (
    <div className={`container grid md:grid-cols-2 items-center gap-y-12 gap-x-16 pt-8`}>
      <div>
        <div className="mx-auto max-w-[540px] w-full">
          <TextContent innerOnly {...mastheadContent} />
        </div>
      </div>
      <div>
        <div
          className={`max-w-[540px] w-full mx-auto md:max-w-[483px]`}
          style={{ maxWidth: mediaItem.simpleMedia?.maxwidth ? `${mediaItem.simpleMedia.maxwidth}px` : '' }}
        >
          <Image className="rounded-[30px] overflow-hidden" image={mediaItem.simpleMedia.image} />
        </div>
      </div>
    </div>
  );
}
