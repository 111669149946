import React, { useState } from 'react';
import { ButtonArrow } from '../buttons/Button';
import { FileUpload, FormCheck } from '../Icon';
import HandShake from '~/assets/images/handshake.png';
import Image from '../../elements/Image';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';

export const ApplicationForm = ({ role }) => {
  const [fileAdded, setFile] = useState('false');

  const handleOnChange = (e) => {
    setFile('true');
  };

  const [formSent, setFormStatus] = useState('initial');

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: 'post',
      url: 'https://getform.io/f/228d9162-a439-4a69-8579-aff0d3b6ef56',
      data: new FormData(form),
    })
      .then((r) => {
        handleServerResponse(true, 'Thanks!', form);
        console.log('success');
        setFormStatus('success');
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form);
        console.log('fail');
        setFormStatus('fail');
      });
  };

  return (
    <AnimatePresence exitBeforeEnter>
      {formSent !== 'success' && (
        <motion.div
          initial={{ opacity: 1, y: 0 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 1.5, ease: 'easeInOut' }}
        >
          <form className="text-left" onSubmit={handleOnSubmit}>
            <div className="gap-5 md:flex">
              <fieldset className="my-2 md:w-1/2 hidden">
                <label className="mb-4 t-16">Job Role</label>
                <input
                  className="w-full px-4 md:px-6 py-3 rounded-[10px] flex-1 my-2 leading-4 bg-white border-borderGrey focus:!border-midGreen border transition-all duration-200"
                  type="text"
                  required
                  readOnly
                  name="Job Role"
                  value={role}
                />
              </fieldset>
              <fieldset className="my-2 md:w-1/2">
                <label className="mb-4 t-16">Full Name</label>
                <input
                  className="w-full px-4 md:px-6 py-3 rounded-[10px] flex-1 my-2 leading-4 bg-white border-borderGrey focus:!border-midGreen border transition-all duration-200"
                  type="text"
                  required
                  name="Full Name"
                />
              </fieldset>
              <fieldset className="my-2 md:w-1/2">
                <label className="mb-4 t-16">Email Address</label>
                <input
                  className="w-full px-4 md:px-6 py-3 rounded-[10px] flex-1 my-2 leading-4 bg-white border-borderGrey focus:!border-midGreen border transition-all duration-200"
                  type="email"
                  required
                  name="Email"
                />
              </fieldset>
            </div>
            <div className="gap-5 md:flex">
              <fieldset className="w-full mt-2 mb-4">
                <label className="mb-4 t-16">Upload your file</label>
                <label
                  htmlFor="file"
                  className="group relative cursor-pointer flex flex-col items-center input-file-style relative w-full min-h-[144px] p-4 py-8 md:p-6 md:py-10 rounded-md flex-1 my-2 leading-4 bg-white border-dashed border-borderGrey focus:!border-midGreen border transition-all duration-200"
                >
                  <div className="flex space-x-4 items-center">
                    <div className="w-[20px] md:w-[33px]">
                      <FileUpload />
                    </div>
                    <span className="t-12 md:text-base">Drop files here to upload</span>
                  </div>
                  <span className="block text-center mt-1 mb-4 opacity-[.3] t-12 md:text-base">or</span>
                  <span className="cursor-pointer btn btn-coral group-hover:!bg-darkGreen flex items-center space-x-[9px] whitespace-nowrap justify-center mx-auto px-[18px] !py-2 t-14 scale-[.8] md:scale-[1]">
                    <span>Browse Files</span>
                    <ButtonArrow color={'coral'} arrowFill={false} />
                  </span>
                  <input
                    type="file"
                    required
                    id="file"
                    name="file"
                    onChange={handleOnChange}
                    className="upload-file cursor-pointer opacity-0 absolute inset-0"
                  />
                </label>
              </fieldset>
            </div>
            {fileAdded === 'true' && (
              <div className="bg-darkTaupe rounded px-4 py-2 flex space-x-6 items-center">
                <div className="w-8 h-8">
                  <FormCheck />
                </div>
                <p>File Uploaded</p>
              </div>
            )}
            <fieldset className="flex items-start w-full gap-3 mt-5 mb-8 max-w-[575px]">
              <input
                name="Privacy"
                type="checkbox"
                className="relative top-1 !w-[25px] !h-[14px]"
                id="privacy"
                required
              />
              <label className="t-14 w-[95%] lg:w-full" htmlFor="privacy">
                I accept Setpoint’s{' '}
                <a
                  className="underline text-coral hover:text-darkGreen transition-all duration-200"
                  href="/privacy-policy/"
                >
                  Privacy Policy
                </a>{' '}
                and agree to receive promotional messages from Setpoint about its products and services. You can
                unsubscribe at any time by clicking on the link at the bottom of our emails.
              </label>
            </fieldset>
            <button
              className="btn btn-coral flex items-center space-x-[9px] whitespace-nowrap justify-center mx-auto md:ml-0"
              type="submit"
            >
              <span>Submit application</span>

              <ButtonArrow color={'coral'} arrowFill={false} />
            </button>
          </form>
        </motion.div>
      )}
      {formSent === 'success' && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.5, ease: 'easeInOut' }}
          className="t-16 max-w-[395px] text-center mx-auto mt-4"
        >
          <div className="w-[120px] md:w-[200px] h-[100px] md:h-[132px] mx-auto mb-6 md:mb-8">
            <img src={HandShake} className={`w-full h-full object-contain object-center`} />
          </div>
          <h4 className="t-30 mb-4 lg:mb-6">We have received your application. </h4>
          <p className="t-14">A member of the Setpoint team will be in touch.</p>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
