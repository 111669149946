import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '~/components/Layout';
import { Post } from '~/components/flexible/LatestNews';
import DateFormat from '~/components/elements/date';
import Image from '~/components/elements/Image';

import { ArticleRenderer } from '~/components/ArticleRenderer';
import { Link } from 'gatsby';

import { ButtonArrow } from '~/components/elements/buttons/Button';

import Seo from 'gatsby-plugin-wpgraphql-seo';
import { Section } from '~/components/elements/Section';

export default function articlePage({ data }: any) {
  const { wpPage, wpPost, wp, cta } = data;
  const postContent = wpPost?.postTypePost;

  return (
    <Layout wp={wp} styleVersion="v2">
      <Seo post={wpPost} title={wpPost.title} />

      <ArticleMasthead content={wpPost} />

      <section className="my-12 md:mt-16 md:mb-24">
        <div className="container !px-[25px]">
          <article className="flex-auto max-w-[800px] mx-auto">
            <ArticleRenderer
              prefix="Post_Posttypepost_ArticleContent_ArticleContent_"
              content={postContent?.articleContent?.articleContent}
            />
          </article>
        </div>
      </section>
      {postContent?.relatedArticles && postContent?.relatedArticles?.length > 0 ? (
        <RelatedArticles articles={postContent?.relatedArticles} />
      ) : null}
      {cta?.postTypePage?.sections?.length > 0 ? (
        <Section {...cta?.postTypePage?.sections[0]} prefix="ReusableBlock_Posttypepage_Sections_Section_Components_" />
      ) : null}
    </Layout>
  );
}

export const ArticleMasthead = ({ content }) => {
  const author = content?.author?.node?.authorDisplayInformation?.displayDetails;
  const isPress = content?.categories?.nodes?.some((item) => item?.name === 'Press Releases');
  return (
    <section className="pt-20 md:pt-[100px]">
      <div className="container !px-[25px]">
        <div>
          <Link
            to={'/resources/'}
            className="text-dark  font-medium inline-flex items-center space-x-3 hover:opacity-85 transition-opacity duration-150"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g>
                <circle cx="12" cy="12" r="12" fill="#F66B4C" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.76222 8.1591L6.16553 11.6159C5.94482 11.828 5.94482 12.172 6.16553 12.3841L9.76222 15.8409C9.98293 16.053 10.3408 16.053 10.5615 15.8409C10.7822 15.6288 10.7822 15.2849 10.5615 15.0727L7.92959 12.5432H17V11.4568H7.92959L10.5615 8.92728C10.7822 8.71515 10.7822 8.37122 10.5615 8.1591C10.3408 7.94697 9.98293 7.94697 9.76222 8.1591Z"
                  fill="white"
                />
              </g>
            </svg>
            <span className="t-16">Go Back</span>
          </Link>
        </div>

        <div className="flex-auto max-w-[800px] mx-auto mt-8">
          <div>
            <div className="t-16 text-dark text-opacity-60 space-x-1 mb-7">
              {content?.categories?.nodes?.map((item, i) => (
                <span
                  key={`cat${i}`}
                  dangerouslySetInnerHTML={{
                    __html: item?.name + (i < content?.categories?.nodes.length - 1 ? ', ' : ''),
                  }}
                />
              ))}
            </div>

            <h1 className="text-dark t-52 leading-[1.1em] mb-6" dangerouslySetInnerHTML={{ __html: content?.title }} />

            {!isPress ? (
              <div className="flex flex-col md:flex-row t-14 text-dark text-opacity-60">
                <DateFormat dateString={content?.date} />
                {author?.name?.length > 0 ? (
                  <>
                    <span className="px-2 hidden md:block">•</span>
                    <div>
                      Written by {author.name}
                      {author.role ? ', ' + author.role : ''}
                    </div>
                  </>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export const RelatedArticles = ({ articles }) => {
  return (
    <section className=" my-16 md:my-24 lg:my-28 container !px-[25px] !max-w-[1200px]">
      <h4 className="t-36 mb-8 md:mb-12 text-dark">Read more related articles</h4>
      <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-[24px] sm:gap-x-[24px] md:gap-y-0 card-group-hover">
        {articles.map((article, i) => (
          <Post post={article} key={`related${i}`} />
        ))}
      </div>
    </section>
  );
};

export const ArticleItem = ({ article }) => {
  return (
    <Link to={article?.uri} className="rounded-[32px] border border-borderGrey bg-white p-4 pb-8 md:p-6 lg:p-[40px]">
      <div className="w-full aspect-[10/7] rounded-[20px] overflow-hidden safari-overflow">
        <Image
          image={article?.postTypePost?.summary?.image}
          objectFit={`cover`}
          className={`w-full h-full object-cover object-center`}
        />
      </div>
      <div>
        <div className="t-14 space-x-4 md:space-x-[20px] mb-[12px] mt-6 md:mt-[35px]">
          {article?.categories?.nodes.map((item, i) => (
            <span className="text-coral" key={i}>
              {item?.name}
            </span>
          ))}
          <DateFormat dateString={article?.date} />
        </div>
        <h3 className="t-24-h line-clamp-3 mb-6 md:mb-[30px]">{article?.title}</h3>
        <span className="t-18 text-coral font-bold underline">Read Story</span>
      </div>
    </Link>
  );
};

export const CTA = ({ ctaData }) => {
  const sectionClasses = [];
  const ctaTitle = [];
  const ctaContent = [];
  const ctaLinkTitle = [];
  const ctaLinkTarget = [];
  const ctaLinkUri = [];

  // background controls
  if (ctaData?.backgroundColour === 'marroon') {
    sectionClasses.push('bg-maroon');
  } else {
    sectionClasses.push('bg-darkGreen');
  }
  // Title Controls
  if (ctaData?.heading) {
    ctaTitle.push(ctaData?.heading);
  } else {
    ctaTitle.push('Unlock modern real estate transactions.');
  }
  // Content Controls
  if (ctaData?.content) {
    ctaContent.push(ctaData?.content);
  } else {
    ctaContent.push('Simple software, easy-to-access capital — in one place, for the first time.');
  }

  if (ctaData?.link) {
    ctaLinkTitle.push(ctaData?.link?.title);
    ctaLinkTarget.push(ctaData?.link?.target);
    ctaLinkUri.push(ctaData?.link?.uri);
  } else {
    ctaLinkTitle.push('Speak with us');
    ctaLinkTarget.push('');
    ctaLinkUri.push('/speak-with-us/');
  }

  return (
    <section
      className={`section section-top section-bot contain-paint !text-white space-y-8 md:space-y-12 xl:space-y-20 section-contained ${sectionClasses}`}
    >
      <div className="container">
        <div className="text-center  mx-auto max-w-[900px]">
          <h3 className="mx-auto pt-1 text-center text-h2 mb-2 md:mb-3 max-w-[660px]">{ctaTitle}</h3>
          <div className="pt-2 mx-auto t-20 max-w-[678px]">{ctaContent}</div>
          <div className=" flex justify-center !mt-[22px]">
            <div className="md:flex space-y-2.5 md:space-y-0 md:space-x-[15px] w-full md:w-auto">
              <Link
                className="btn btn-orange flex items-center space-x-[9px] whitespace-nowrap justify-center group"
                to={`${ctaLinkUri}`}
                target={`${ctaLinkTarget}`}
              >
                <span>{ctaLinkTitle}</span>
                <span className="inline-block group-hover:translate-x-[6px] transition-all duration-200">
                  <ButtonArrow color={'orange'} />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const pageQuery = graphql`
  query Article($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }

    cta: wpReusableBlock(databaseId: { eq: 2702 }) {
      id
      postTypePage {
        fieldGroupName
        sections {
          ... on WpReusableBlock_Posttypepage_Sections_Section {
            acfeFlexibleLayoutTitle
            backgroundColor
            classname
            clearHeader
            sectionStyle
            components {
              ... on WpReusableBlock_Posttypepage_Sections_Section_Components_TextContent {
                centered
                className
                content
                contentSize
                fieldGroupName
                heading
                headingSize
                links {
                  buttonColour
                  fieldGroupName
                  link {
                    target
                    title
                    url
                  }
                  removeArrow
                  type
                }
                subheading
                visibleFields
                visualControls {
                  fieldGroupName
                  restrictWidth {
                    content
                    fieldGroupName
                    heading
                    main
                  }
                  subheadingColor
                  subheadingSize
                }
              }
            }
            containsStickyComponent
            innerSpacing
            noPaddingBottom
            noPaddingTop
            paddingBottomControls
            paddingTopControls
          }
        }
      }
    }

    wpPost(id: { eq: $id }) {
      ...GeneratedWpPost
      title
      categories {
        nodes {
          name
        }
      }
      author {
        node {
          authorDisplayInformation {
            displayDetails {
              name
              role
              image {
                altText
                publicUrl
                sourceUrl
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
      postTypePost {
        summary {
          summary
          image {
            altText
            publicUrl
            sourceUrl
            localFile {
              publicURL
            }
          }
        }
        articleContent {
          articleContent {
            ... on WpPost_Posttypepost_ArticleContent_ArticleContent_Text {
              fieldGroupName
              text
            }
            ... on WpPost_Posttypepost_ArticleContent_ArticleContent_Quote {
              fieldGroupName
              text
              cite
              position
              logo {
                altText
                publicUrl
                sourceUrl
                localFile {
                  publicURL
                }
              }
            }
            ... on WpPost_Posttypepost_ArticleContent_ArticleContent_Image {
              fieldGroupName
              image {
                altText
                publicUrl
                sourceUrl
                localFile {
                  publicURL
                }
              }
            }
            ... on WpPost_Posttypepost_ArticleContent_ArticleContent_Bullet {
              fieldGroupName
              heading
              list {
                listItem
              }
            }
            ... on WpPost_Posttypepost_ArticleContent_ArticleContent_Statistics {
              fieldGroupName
              statisticItem {
                number
                description
              }
            }
          }
        }
        relatedArticles {
          ... on WpPost {
            id
            title
            uri
            date
            categories {
              nodes {
                name
              }
            }
            postTypePost {
              summary {
                summary
                image {
                  altText
                  publicUrl
                  sourceUrl
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
        customCta {
          heading
          content
          link {
            target
            title
            url
          }
          backgroundColour
        }
      }
    }
    wp {
      ...GeneratedWp
      header {
        optionsHeader {
          menu {
            link {
              url
              title
            }
            showSubmenu
            submenu {
              type
              withPostMenu {
                leftColumn {
                  heading
                  links {
                    link {
                      url
                      title
                    }
                    description
                  }
                }
              }
              withImageMenu {
                leftColumn {
                  heading
                  links {
                    link {
                      url
                      title
                    }
                  }
                }
                rightColumn {
                  heading
                  links {
                    link {
                      url
                      title
                    }
                  }
                }
                image {
                  ...Image
                }
              }
            }
          }
        }
      }
    }
  }
`;
