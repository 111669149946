import React from "react";
import Image from "../../elements/Image";
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const IconGrid = ({
  gridItems
}) => {
  
  return(
    <InViewAnimator className="section-lrg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-4 sm:gap-4 md:gap-[24px]"
      inner={(controls) => (
        <>
          {gridItems.map((item, i) => (
            <InViewAnimatorBasicItem key={i} className="rounded-[20px] border border-borderGrey bg-white text-center px-6 pt-8 pb-10 md:pt-[50px] md:pb-[50px] lg:pb-[70px]"
              controls={controls}
              delay={i/4}
              duration={1}>
              <div className="w-[120px] md:w-[200px] h-[100px] md:h-[132px] mx-auto mb-6 md:mb-8">
                <Image image={item.icon} className={`w-full h-full object-contain object-center`} />
              </div>
              <h4 className="t-30 max-w-[240px] mx-auto">{item.heading}</h4>
            </InViewAnimatorBasicItem>
          ))}
        </>
      )}
    />
  );
};